<template>
  <v-menu
    bottom
    left
    min-width="200"
    offset-y
    origin="top right"
    transition="scale-transition"
  >
    <template #activator="{ attrs, on }">
      <v-btn
        class="ml-2"
        min-width="0"
        text
        v-bind="attrs"
        v-on="on"
      >
        <v-icon>mdi-account</v-icon>
      </v-btn>
    </template>

    <v-list
      :tile="false"
      flat
      nav
    >
      <template v-for="(p, i) in profile">
        <v-divider
          v-if="p.divider"
          :key="`divider-${i}`"
          class="mb-2 mt-2"
        />

        <app-bar-item
          v-else
          :key="`item-${i}`"
          :to="p.to"
          @click="onLogout"
        >
          <v-list-item-title v-text="p.title" />
        </app-bar-item>
      </template>
    </v-list>
  </v-menu>
</template>

<script>
  import {logout} from "@/util/helpers";

  export default {
    name: 'DefaultAccount',

    data: () => ({
      profile: [
        { title: 'Profile', to: '/' },
        // { title: 'Settings', to: '/'},
        // { divider: true },
        {
          title: 'Log out',
          to: '/pages/login',
        },
      ],
    }),

    methods: {
      onLogout() {
        logout()
      }
    }
  }
</script>

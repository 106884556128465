import { Vue, Component } from 'vue-property-decorator';
import { api } from "@/util/helpers";

@Component
export default class MoveRecord extends Vue {
    itemType = ''

    setItemType (type) {
        this.itemType = type
    }

    onCounterChange(item, number) {
        item.counterForMoving = number
    }

    async onMoveUp(item) {
        console.log(item)
        if (!isNaN(+item.counterForMoving) && item.counterForMoving !== 0) {
            this.loading = true
            const url = '/admin/position/' + this.itemType + '/' + item.id + '/' + (-item.counterForMoving)
            await api.post(url)
            await this.loadData()
        }
    }

    async onMoveDown(item) {
        if (!isNaN(+item.counterForMoving) && item.counterForMoving !== 0) {
            this.loading = true
            const url = '/admin/position/' + this.itemType + '/' + item.id + '/' + item.counterForMoving
            await api.post(url)
            await this.loadData()
        }
    }
}

var render = function render(){var _vm=this,_c=_vm._self._c;return _c('v-container',{attrs:{"id":"data-tables-view","fluid":"","tag":"section"}},[_c('v-row',{staticClass:"mb-10"},[_c('v-col',[_c('app-btn',{staticClass:"mr-2 mb-2",attrs:{"color":"default"},on:{"click":_vm.loadData}},[_c('v-icon',{attrs:{"color":"darken-2"}},[_vm._v(" mdi-refresh ")])],1),_c('v-btn',{staticClass:"mb-2",attrs:{"color":"default"},on:{"click":_vm.onAddNewItem}},[_vm._v(" Dodaj nowy element ")])],1)],1),_c('ListFilter',{ref:"filter",attrs:{"filters":_vm.filters,"callable":_vm.loadData,"name":"male-hairstyles"}}),_c('material-card',{attrs:{"icon":"mdi-face-man-profile","icon-small":"","color":"accent","title":"Włosy męskie"}},[_c('v-card-text',[_c('v-text-field',{staticClass:"ml-auto",staticStyle:{"max-width":"250px"},attrs:{"append-icon":"mdi-magnify","hide-details":"","label":"Wyszukaj","single-line":""},on:{"change":_vm.onSearch},model:{value:(_vm.search),callback:function ($$v) {_vm.search=$$v},expression:"search"}}),_c('v-divider',{staticClass:"mt-3"}),_c('v-data-table',{attrs:{"items-per-page":_vm.options.itemsPerPage,"headers":_vm.headers,"items":_vm.items,"search":_vm.search,"sort-by":[],"sort-desc":[],"multi-sort":"","loading":_vm.loading,"loading-text":"Ładuję dane, proszę czekać...","options":_vm.options,"server-items-length":_vm.totalRows,"footer-props":{
          showFirstLastPage: true,
          firstIcon: 'mdi-arrow-collapse-left',
          lastIcon: 'mdi-arrow-collapse-right',
          prevIcon: 'mdi-minus',
          nextIcon: 'mdi-plus'
        }},on:{"update:search":function($event){_vm.search=$event},"update:options":[function($event){_vm.options=$event},_vm.onUpdate],"update:page":_vm.onUpdatePage,"update:items-per-page":_vm.onUpdateItemsPerPage},scopedSlots:_vm._u([{key:`item.src`,fn:function({ item }){return [_c('v-img',{staticClass:"mt-2 mb-2",attrs:{"src":item.src,"max-height":"90","contain":""}})]}},{key:`item.actions`,fn:function({ item }){return [_c('v-icon',{staticClass:"mr-2",on:{"click":function($event){return _vm.onEditItem(item)}}},[_vm._v(" mdi-pencil ")]),_c('v-icon',{staticClass:"ml-4",on:{"click":function($event){return _vm.onDeleteItem(item)}}},[_vm._v(" mdi-delete ")])]}}],null,true)})],1)],1),_c('v-dialog',{attrs:{"width":"500"},model:{value:(_vm.dialog),callback:function ($$v) {_vm.dialog=$$v},expression:"dialog"}},[_c('v-card',[_c('v-card-title',{staticClass:"text-h5 grey lighten-2"},[_vm._v(" Na pewno usunąć ten cytat? ")]),_c('v-card-text',[_c('br'),(_vm.itemToDelete !== null)?_c('v-img',{staticClass:"mt-2 mb-2",attrs:{"src":_vm.itemToDelete.src,"max-height":"180","contain":""}}):_vm._e()],1),_c('v-divider'),_c('v-card-actions',[_c('v-spacer'),_c('v-btn',{attrs:{"color":"primary","text":""},on:{"click":_vm.onDelete}},[_vm._v(" Tak ")]),_c('v-btn',{attrs:{"color":"primary","text":""},on:{"click":function($event){_vm.dialog = false}}},[_vm._v(" Nie ")])],1)],1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }
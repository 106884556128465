import { render, staticRenderFns } from "./RabbitEdit.vue?vue&type=template&id=4ab8e0b4&scoped=true&"
import script from "./RabbitEdit.vue?vue&type=script&lang=js&"
export * from "./RabbitEdit.vue?vue&type=script&lang=js&"
import style0 from "./RabbitEdit.vue?vue&type=style&index=0&id=4ab8e0b4&prod&lang=sass&scoped=true&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "4ab8e0b4",
  null
  
)

export default component.exports
<template>
  <v-container
    id="regular-forms-view"
    fluid
    tag="section"
  >
    <v-row>
      <v-col
        cols="12"
        md="6"
      >
        <app-btn
          color="default"
          class="mr-2 mb-2"
          to="/discounts"
        >
          <v-icon
            large
            color="darken-2"
          >
            mdi-keyboard-return
          </v-icon>
        </app-btn>
        <app-btn
          color="default"
          class="mr-2 mb-2"
          @click="save"
        >
          {{ id === "0" ? "Dodaj zniżkę" : "Zapisz zmiany" }}
        </app-btn>
        <app-btn
          color="default"
          class="mr-2 mb-2"
          @click="saveAndClose"
        >
          {{ id === '0' ? 'Dodaj zniżkę i zamknij' : 'Zapisz zmiany i zamknij' }}
        </app-btn>
      </v-col>
    </v-row>

    <v-row>
      <v-col
        cols="6"
        md="6"
      >
        <material-card
          heading="Podstawowe dane"
          color="accent"
        >
          <v-card-text>
            <v-text-field
              v-model="discountData.code"
              class="mb-n3"
              label="Kod"
            />

            <vc-date-picker
              v-model="validFrom"
              mode="dateTime"
              is24hr
              timezone="utc"
            >
              <template #default="{ inputValue, inputEvents }">
                <v-text-field
                  label="Ważny od"
                  class="mb-n3"
                  :value="inputValue"
                  v-on="inputEvents"
                />
              </template>
            </vc-date-picker>

            <vc-date-picker
              v-model="validTo"
              mode="dateTime"
              is24hr
              timezone="utc"
            >
              <template #default="{ inputValue, inputEvents }">
                <v-text-field
                  label="Ważny do"
                  class="mb-n3"
                  :value="inputValue"
                  v-on="inputEvents"
                />
              </template>
            </vc-date-picker>

            <v-text-field
              v-model.number="discountData.volume"
              class="mb-n3"
              label="Liczba kuponów"
              type="number"
            />
          </v-card-text>
        </material-card>
      </v-col>

      <v-col
        cols="6"
        md="6"
      >
        <material-card
          heading="Reguła"
          color="accent"
        >
          <v-card-text>
            <v-select
              v-bind="discountType"
              :items="items"
              label="Rodzaj reguły"
              class="mb-n3"
              item-text="text"
              item-value="value"
              return-object
              @change="onDiscountTypeChange"
            />
            <template v-if="discountData.type === TYPE_PERCENTAGE">
              <v-text-field
                v-model="discountData.rule.minValue"
                type="number"
                class="mb-n3"
                label="Wartość minimalna produktów"
              />
              <v-text-field
                v-model="discountData.rule.percentage"
                type="number"
                class="mb-n3"
                label="Wartość procentowego upustu"
              />
            </template>
            <template v-else-if="discountData.type === TYPE_AMOUNT">
              <v-text-field
                v-model="discountData.rule.minValue"
                type="number"
                class="mb-n3"
                label="Wartość minimalna produktów"
              />
              <v-text-field
                v-model="discountData.rule.discountValue"
                type="number"
                class="mb-n3"
                label="Wartość upustu"
              />
            </template>
            <template v-else-if="discountData.type === TYPE_QUANTITY">
              <v-text-field
                v-model="discountData.rule.minItemsNumber"
                type="number"
                class="mb-n3"
                label="Minimalna liczba produktów przed zastosowaniem upustu"
              />
              <v-text-field
                v-model="discountData.rule.itemsFree"
                type="number"
                class="mb-n3"
                label="Liczba darmowych produktów"
              />
            </template>
          </v-card-text>
        </material-card>
      </v-col>
    </v-row>

    <ProgressPopup
      v-if="saveLoader"
      text="Zapisuję..."
    />
  </v-container>
</template>

<script>
import { Vue, Component, Prop, Watch } from "vue-property-decorator";
import moment from "moment";
import { api } from "@/util/helpers";
import ProgressPopup from "@/app/common/ProgressPopup";

@Component({
  components: { ProgressPopup }
})
export default class DiscountEdit extends Vue {
  @Prop(String) id;

  zoom = 0;
  saveLoader = false;
  file = null;
  validFrom = new Date();
  validTo = new Date();

  TYPE_PERCENTAGE = 0
  TYPE_QUANTITY = 1
  TYPE_AMOUNT = 2
  items = [
    { value: this.TYPE_PERCENTAGE, text: 'Zniżka procentowa (obniża wartość produktów o zadany procent)' },
    { value: this.TYPE_QUANTITY, text: 'Zniżka produktowa (umożliwia bezpłatny zakup produktów)' },
    { value: this.TYPE_AMOUNT, text: 'Zniżka wartościowa (obniża wartość produktów)' }
  ];

  discountType = {}
  discountData = {
    code: '',
    type: null,
    rule: {
      minValue: 0,
      percentage: 0,
      discountValue: 0,
      minItemsNumber: 0,
      itemsFree: 0
    },
    validFrom: '',
    validTo: '',
    volume: 0
  };

  async mounted() {
    await this.fetchDiscount(this.id);
  }

  onDiscountTypeChange(discountType) {
    this.discountData.type = discountType.value
  }

  async fetchDiscount(discountId) {
    if (discountId !== '0') {
      const result = await api.get('/admin/discount/' + discountId);
      if (result && result.data.success) {
        this.discountData = result.data;
        this.discountType = this.items.find(item => item.value === this.discountData.type)
        this.validFrom = this.discountData.validFrom;
        this.validTo = this.discountData.validTo;
      }
    } else {
      this.discountType = this.items.find(item => item.value === this.TYPE_PERCENTAGE)
      this.discountData.type = this.TYPE_PERCENTAGE
    }
  }

  async save() {
    this.validFrom.setSeconds(0);
    this.validTo.setSeconds(59);
    this.discountData.validFrom = moment(this.validFrom).toISOString();
    this.discountData.validTo = moment(this.validTo).toISOString();
    this.discountData.rule.itemsFree = +this.discountData.rule?.itemsFree || 0
    this.discountData.rule.minValue = +this.discountData.rule?.minValue || 0
    this.discountData.rule.percentage = +this.discountData.rule?.percentage || 0
    this.discountData.rule.discountValue = +this.discountData.rule?.discountValue || 0
    this.discountData.rule.minItemsNumber = +this.discountData.rule?.minItemsNumber || 0

    this.saveLoader = true;
    let result;
    if (this.id !== '0') {
      result = await api.patch(
        '/admin/discount/' + this.discountData.id,
        this.discountData
      );
    } else {
      result = await api.post('/admin/discount', this.discountData);
    }

    this.saveLoader = false;
    if (!result || !result.data.success) {
      alert('Błąd podczas zapisu zmian');
      return false
    } else {
      if (this.id === '0') {
        await this.$router.push('/discount/' + result.data.id);
      }
    }

    return true
  }

  async saveAndClose() {
    if (await this.save()) {
      await this.$router.push('/discounts')
    }
  }
}
</script>

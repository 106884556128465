<template>
  <v-container
    id="regular-forms-view"
    fluid
    tag="section"
  >
    <v-row>
      <v-col
        cols="12"
        md="6"
      >
        <app-btn
          color="default"
          class="mr-2 mb-2"
          to="/cups"
        >
          <v-icon
            large
            color="darken-2"
          >
            mdi-keyboard-return
          </v-icon>
        </app-btn>
        <app-btn
          color="default"
          class="mr-2 mb-2"
          @click="save"
        >
          {{ id === '0' ? 'Dodaj kubek' : 'Zapisz zmiany' }}
        </app-btn>
        <app-btn
          color="default"
          class="mr-2 mb-2"
          @click="saveAndClose"
        >
          {{ id === '0' ? 'Dodaj kubek i zamknij' : 'Zapisz zmiany i zamknij' }}
        </app-btn>
      </v-col>
    </v-row>

    <v-row>
      <v-col
        cols="12"
        md="6"
      >
        <material-card
          heading="Dane ogólne"
          color="accent"
        >
          <v-card-text>
            <v-radio-group
              :row="true"
              label="Domyśny kubek?"
              :value="cupData.isDefault"
            >
              <v-radio
                label="Tak"
                :value="true"
                @click="cupData.isDefault=true"
              />
              <v-radio
                label="Nie"
                :value="false"
                @click="cupData.isDefault=false"
              />
            </v-radio-group>
            <v-file-input
              id="cup_right"
              ref="file"
              accept="image/png"
              label="Zdjęcie z uchem po prawej stronie"
              prepend-icon="mdi-camera"
              @change="handleCupRightUpload"
            />
            <v-file-input
              id="cup_middle"
              ref="file"
              accept="image/png"
              label="Zdjęcie z uchem z tyłu"
              prepend-icon="mdi-camera"
              @change="handleCupMiddleUpload"
            />
            <v-file-input
              id="cup_left"
              ref="file"
              accept="image/png"
              label="Zdjęcie z uchem po lewej stronie"
              prepend-icon="mdi-camera"
              @change="handleCupLeftUpload"
            />
            <v-file-input
              id="cup_thumbnail"
              ref="file"
              accept="image/png"
              label="Zdjęcie miniatury"
              prepend-icon="mdi-camera"
              @change="handleCupThumbnailUpload"
            />
          </v-card-text>
        </material-card>
      </v-col>

      <v-col
        v-if="id !== '0'"
        :key="`img1-${reloadKey}`"
        cols="4"
        md="2"
      >
        <v-img
          :src="cupData.leftImageURL"
        />
      </v-col>
      <v-col
        v-if="id !== '0'"
        :key="`img3-${reloadKey}`"
        cols="4"
        md="2"
      >
        <v-img
          :src="cupData.middleImageURL"
        />
      </v-col>
      <v-col
        v-if="id !== '0'"
        :key="`img2-${reloadKey}`"
        cols="4"
        md="2"
      >
        <v-img
          :src="cupData.rightImageURL"
        />
      </v-col>
    </v-row>

    <v-row>
      <v-col
        cols="12"
        md="6"
      >
        <material-card
          heading="Stan magazynowy"
          color="accent"
        >
          <v-card-text>
            <v-checkbox
              v-model="volumeDisabled"
              label="Zablokowana zmiana stanu magazynowego"
            />
            <v-text-field
              v-model="cupData.volume"
              class="mb-n3"
              label="Liczba kubków na stanie"
              type="number"
              :readonly="volumeDisabled"
            />
            <v-radio-group
              :row="true"
              label="Czy kubek jest na stanie?"
              :value="cupData.inStock"
            >
              <v-radio
                label="Tak"
                :value="true"
                @click="cupData.inStock=true"
              />
              <v-radio
                label="Nie"
                :value="false"
                @click="cupData.inStock=false"
              />
            </v-radio-group>
          </v-card-text>
        </material-card>
      </v-col>
      <v-col
        cols="12"
        md="6"
      >
        <material-card
          heading="Parametry"
          color="accent"
        >
          <v-card-text>
            <v-text-field
              v-model="cupData.name"
              class="mb-n3"
              label="Rodzaj kubka"
            />
            <v-text-field
              v-model="cupData.capacity"
              class="mb-n3"
              type="number"
              label="Pojemność w ml"
            />
            <v-text-field
              v-model="cupData.cost"
              class="mb-n3"
              type="number"
              label="Dodatkowy koszt"
            />
          </v-card-text>
        </material-card>
      </v-col>
    </v-row>

    <ProgressPopup
      v-if="saveLoader"
      text="Zapisuję..."
    />
  </v-container>
</template>

<script>
import { Vue, Component, Prop } from 'vue-property-decorator';
import { api } from "@/util/helpers";
import ProgressPopup from "@/app/common/ProgressPopup";
import MaterialCard from "@/components/MaterialCard";

@Component({
  components: { MaterialCard, ProgressPopup }
})
export default class CupEdit extends Vue {
  @Prop(String) id

  zoom = 0
  cupRightImage = null
  cupMiddleImage = null
  cupLeftImage = null
  cupThumbnailImage = null
  saveLoader = false
  reloadKey = 0
  volumeDisabled = true
  cupData = {
    title: '',
    isDefault: false,
    volume: 0,
    inStock: false
  }

  async mounted() {
    await this.fetchCup(this.id)
  }

  async fetchCup(cupId) {
    if (cupId !== "0") {
      const result = await api.get('/admin/cup/' + cupId)
      if (result && result.data.success) {
        this.cupData = result.data
        this.cupData.volume = this.cupData.volume || 0
      }
    }
  }

  handleCupRightUpload(file) {
    this.cupRightImage = file
  }

  handleCupLeftUpload(file) {
    this.cupLeftImage = file
  }

  handleCupThumbnailUpload(file) {
    this.cupThumbnailImage = file
  }

  handleCupMiddleUpload(file) {
    this.cupMiddleImage = file
  }

  zoomIn() {
    this.zoom = (this.zoom + 10) || 100
  }

  async saveAndClose() {
    if (await this.save()) {
      await this.$router.push('/cups')
    }
  }

  async save() {
    this.saveLoader = true

    let formData = new FormData();
    formData.append("name", this.cupData.name)
    formData.append("capacity", this.cupData.capacity || '')
    formData.append("cost", this.cupData.cost || '0')
    formData.append("volumeDisabled", this.volumeDisabled)
    formData.append("volume", this.cupData.volume || '0')
    formData.append("inStock", this.cupData.inStock ?? false)
    formData.append("isDefault", this.cupData.isDefault ?? false)
    formData.append("cupRightImage", this.cupRightImage)
    formData.append("cupMiddleImage", this.cupMiddleImage)
    formData.append("cupLeftImage", this.cupLeftImage)
    formData.append("cupThumbnailImage", this.cupThumbnailImage)

    let result
    if (this.id !== "0") {
      result = await api.patch('/admin/cup/' + this.cupData.id, formData, {
        'Content-Type': 'multipart/form-data'
      })
    } else {
      result = await api.post('/admin/cup', formData, {
        'Content-Type': 'multipart/form-data'
      })
    }

    if (!result || !result.data.success) {
      alert('Błąd podczas zapisu zmian w kubku')
      console.log(result)
      this.saveLoader = false
      return false
    }

    this.newImage = null
    this.saveLoader = false
    if (this.id === '0') {
      await this.$router.push('/cup/' + result.data.id)
    } else {
      this.cupData = result.data
      this.reloadImage()
    }
    this.cupData.volume = this.cupData.volume || 0
    return true
  }

  reloadImage() {
    this.reloadKey++
  }

  zoomOut() {
    this.zoom = (this.zoom - 10) || 0
  }
}
</script>

<style lang="sass" scoped>
  .grayscale
    filter: grayscale(100%)
</style>

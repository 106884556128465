<template>
  <div>
    <material-card
      icon="mdi-vuetify"
      icon-small
      color="accent"
      title="Filtr"
    >
      <v-card-text>
        <v-row>
          <v-col
            v-for="(filter, key) in filters"
            :key="key"
            :cols="filter.style_columns || 2"
            md="filter.style_columns || 2"
          >
            <v-select
              v-if="filter.type === 'dropdown'"
              v-model="filter.value"
              :items="filter.options"
              :label="filter.label"
            />
            <v-checkbox
              v-if="filter.type === 'checkbox'"
              v-model="filter.value"
              :label="filter.label"
            />
          </v-col>
        </v-row>
      </v-card-text>
    </material-card>
  </div>
</template>

<script>
import {Component, Prop, Vue, Watch} from 'vue-property-decorator';
import {adminModule as store} from "@/app/store";
import {SET_LIST_FIlTER} from "@/app/store/mutation-types";
import {LIST_FILTERS} from "@/app/store/getter-types";

@Component
export default class ListFilter extends Vue {
  @Prop({ type: Array, required: true }) filters
  @Prop({ type: Function, require: false }) callable
  @Prop({ type: String, require: false }) name

  @store.Getter(LIST_FILTERS) listFilters
  @store.Mutation(SET_LIST_FIlTER) setListFilter

  mounting = false

  @Watch('filters', { deep: true })
  onChangeFilters() {
    if (!this.mounting && typeof this.callable !== undefined) {
      if (this.name) {
        this.setListFilter({
          name: this.name,
          filters: this.filters.map(filter => ({ name: filter.name, value: filter.value }))
        })
      }
      this.callable()
    }
  }

  mounted () {
    this.mounting = true
    const filters = this.listFilters[this.name] || null
    if (filters !== null) {
      filters.forEach(filter => {
        const foundFilter = this.filters.find(f => f.name === filter.name) || null
        if (foundFilter !== null) {
          foundFilter.value = filter.value
        }
      })
    }
    this.mounting = false
  }

  buildFilterUrlString () {
    return this.filters.map(filter => `&filter[${filter.name}]=${filter.value}`).join('')
  }
}
</script>
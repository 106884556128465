// Vue
import Vue from 'vue'
import Vuex from 'vuex'
import pathify from '@/plugins/vuex-pathify'

// Modules
import {app, sales, user} from './modules'
import adminModule from '@/app/store/module'

Vue.use(Vuex)

const store = new Vuex.Store({
  modules: {
    app,
    sales,
    user,
    adminModule,
  },
  plugins: [
    pathify.plugin,
  ],
})

store.subscribe(mutation => {
  if (!mutation.type.startsWith('user/')) { return }

  store.dispatch('user/update', mutation)
})

store.dispatch('app/init')

export default store

export const ROOT_DISPATCH = Object.freeze({ root: true })

<template>
  <v-container
    id="data-tables-view"
    fluid
    tag="section"
  >
    <v-row class="mb-10">
      <v-col>
        <app-btn
          color="default"
          class="mr-2 mb-2"
          @click="loadData"
        >
          <v-icon
            color="darken-2"
          >
            mdi-refresh
          </v-icon>
        </app-btn>
        <v-btn
          color="default"
          class="mb-2"
          @click="onAddNewItem"
        >
          Dodaj nowy element
        </v-btn>
      </v-col>
    </v-row>

    <ListFilter
      ref="filter"
      :filters="filters"
      :callable="loadData"
      name="girl-hairstyles"
    />

    <material-card
      icon="mdi-face-woman"
      icon-small
      color="accent"
      title="Włosy dziewczęce"
    >
      <v-card-text>
        <v-text-field
          v-model="search"
          append-icon="mdi-magnify"
          class="ml-auto"
          hide-details
          label="Wyszukaj"
          single-line
          style="max-width: 250px;"
          @change="onSearch"
        />

        <v-divider class="mt-3" />

        <v-data-table
          :items-per-page="options.itemsPerPage"
          :headers="headers"
          :items="items"
          :search.sync="search"
          :sort-by="[]"
          :sort-desc="[]"
          multi-sort
          :loading="loading"
          loading-text="Ładuję dane, proszę czekać..."
          :options.sync="options"
          :server-items-length="totalRows"
          :footer-props="{
            showFirstLastPage: true,
            firstIcon: 'mdi-arrow-collapse-left',
            lastIcon: 'mdi-arrow-collapse-right',
            prevIcon: 'mdi-minus',
            nextIcon: 'mdi-plus'
          }"
          @update:options="onUpdate"
          @update:page="onUpdatePage"
          @update:items-per-page="onUpdateItemsPerPage"
        >
          <template #[`item.src`]="{ item }">
            <v-img
              :src="item.src"
              class="mt-2 mb-2"
              max-height="90"
              contain
            />
          </template>
          <template #[`item.actions`]="{ item }">
            <v-icon
              class="mr-2"
              @click="onEditItem(item)"
            >
              mdi-pencil
            </v-icon>
            <v-icon
              class="ml-4"
              @click="onDeleteItem(item)"
            >
              mdi-delete
            </v-icon>
          </template>
        </v-data-table>
      </v-card-text>
    </material-card>

    <v-dialog
      v-model="dialog"
      width="500"
    >
      <v-card>
        <v-card-title class="text-h5 grey lighten-2">
          Na pewno usunąć tę fryzurę?
        </v-card-title>

        <v-card-text>
          <br>
          <v-img
            v-if="itemToDelete !== null"
            :src="itemToDelete.src"
            class="mt-2 mb-2"
            max-height="180"
            contain
          />
        </v-card-text>

        <v-divider />

        <v-card-actions>
          <v-spacer />
          <v-btn
            color="primary"
            text
            @click="onDelete"
          >
            Tak
          </v-btn>
          <v-btn
            color="primary"
            text
            @click="dialog = false"
          >
            Nie
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-container>
</template>

<script>
import {Vue, Component} from 'vue-property-decorator';

import {api} from "@/util/helpers";
import ListStore from "@/app/mixins/listStore";
import { femaleColorValues, femaleStyleValues } from '@/app/common/Maps';
import ListFilter from '@/app/common/listFilter'

@Component({
  components: { ListFilter }
})
export default class GirlHairstyles extends ListStore {
  viewName = 'girlHairstyles'

  headers = [
    {
      text: 'Zdjęcie',
      value: 'src',
      sortable: false,
    },
    {
      text: 'Kolor',
      value: 'color',
    },
    {
      text: 'Styl',
      value: 'style',
    },
    {
      sortable: false,
      text: '',
      value: 'actions',
    },
  ];

  items = []
  loading = true
  totalRows = 0
  dialog = false
  itemToDelete = null
  filters = [
    {
      value: '',
      options: [ { value: '', text: 'wybierz' }, ...femaleColorValues ],
      label: 'Kolor',
      name: 'color',
      type: 'dropdown'
    },
    {
      value: '',
      options: [ { value: '', text: 'wybierz' }, ...femaleStyleValues ],
      label: 'Styl włosów',
      name: 'style',
      type: 'dropdown'
    }
  ]

  onEditItem(item) {
    this.$router.push('/girl-hairstyle/' + item.id)
  }

  onAddNewItem() {
    this.$router.push('/girl-hairstyle/0')
  }

  onDeleteItem(item) {
    this.itemToDelete = item
    this.dialog = true
  }

  async onDelete() {
    if (this.itemToDelete) {
      this.loading = true
      const url = '/admin/girl-hairstyle/' + this.itemToDelete.id
      const result = await api.delete(url)
      if (!result || !result.data.success) {
        alert('Błąd podczas próby usunięcia fryzury damskiej.')
        this.loading = false
        return
      }
      this.dialog = false
      this.itemToDelete = null
      await this.loadData()
    }
  }

  async loadData() {
    this.loading = true
    const url = '/admin/girl-hairstyles/'
      + this.buildListParam()
      + this.$refs.filter.buildFilterUrlString()
    const result = await api.get(url)
    if (!result || !result.data.success) {
      alert('Błąd podczas próby pobrania danych.')
      return
    }

    this.items = (result.data.data ?? []).map(hairstyle => ({
      id: hairstyle.id,
      src: result.data.sourceUrl + "/" + hairstyle.lowResFilename,
      color: femaleColorValues.find(color => color.value === hairstyle.color)?.text || '',
      style: femaleStyleValues.find(style => style.value === hairstyle.style)?.text || '',
    }))

    this.totalRows = result.data.pagination.totalRows
    this.loading = false
  }
}
</script>

<style scoped>

</style>

<template>
  <v-container
    id="data-tables-view"
    fluid
    tag="section"
  >
    <v-row class="mb-10">
      <v-col
        cols="12"
        md="6"
      >
        <app-btn
          color="default"
          class="mr-2 mb-2"
          @click="loadData"
        >
          <v-icon
            color="darken-2"
          >
            mdi-refresh
          </v-icon>
        </app-btn>
      </v-col>
    </v-row>

    <ListFilter
      ref="filter"
      :filters="filters"
      :callable="loadData"
      name="girl-hairstyles"
    />

    <material-card
      icon="mdi-vuetify"
      icon-small
      color="accent"
      title="Zamówienia"
    >
      <v-card-text>
        <v-text-field
          v-model="search"
          append-icon="mdi-magnify"
          class="ml-auto"
          hide-details
          label="Wyszukaj"
          single-line
          style="max-width: 250px;"
          @change="onSearch"
        />

        <v-divider class="mt-3" />

        <v-data-table
          :items-per-page="options.itemsPerPage"
          :headers="headers"
          :items="items"
          :search.sync="search"
          :sort-by="['serverTime']"
          :sort-desc="[true]"
          multi-sort
          :loading="loading"
          loading-text="Ładuję dane, proszę czekać..."
          :options.sync="options"
          :server-items-length="totalRows"
          :footer-props="{
            showFirstLastPage: true,
            firstIcon: 'mdi-arrow-collapse-left',
            lastIcon: 'mdi-arrow-collapse-right',
            prevIcon: 'mdi-minus',
            nextIcon: 'mdi-plus'
          }"
          @update:options="onUpdate"
          @update:page="onUpdatePage"
          @update:items-per-page="onUpdateItemsPerPage"
        >
          <template #[`item.packageId`]="{ item }">
            <div v-if="item.packageId">
              <a
                :href="`https://furgonetka.pl/zlokalizuj/${item.packageId}`"
                target="_blank"
              >Idź</a>
            </div>
            <div v-else>
              -
            </div>
          </template>
          <template #[`item.orderStatus`]="{ item }">
            <v-chip
              :color="getColor(item.orderStatus)"
              :text-color="getTextColor(item.orderStatus)"
              dark
            >
              {{ item.orderStatus }}
            </v-chip>
          </template>
          <template #[`item.actions`]="{ item }">
            <v-icon
              class="mr-2"
              @click="onEditItem(item)"
            >
              mdi-pencil
            </v-icon>
            <v-icon
              @click="onDeleteItem(item)"
            >
              mdi-delete
            </v-icon>
          </template>
        </v-data-table>
      </v-card-text>
    </material-card>

    <v-dialog
      v-model="dialog"
      width="500"
    >
      <v-card>
        <v-card-title class="text-h5 grey lighten-2">
          Na pewno usunąć te zamówienie?
        </v-card-title>

        <v-card-text>
          <br>
          {{ (itemToDelete || {}).orderId }}
        </v-card-text>

        <v-divider />

        <v-card-actions>
          <v-spacer />
          <v-btn
            color="primary"
            text
            @click="onDelete"
          >
            Tak
          </v-btn>
          <v-btn
            color="primary"
            text
            @click="dialog = false"
          >
            Nie
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-container>
</template>

<script>
  import { Component } from 'vue-property-decorator';

  import {api} from "@/util/helpers";
  import ListStore from "@/app/mixins/listStore";
  import ListFilter from '@/app/common/listFilter'

  @Component({
    components: { ListFilter }
  })
  export default class Orders extends ListStore {
    viewName = 'orders'

    headers = [
      {
        text: 'Id',
        value: 'orderId',
      },
      {
        text: 'Data',
        value: 'serverTime',
      },
      {
        text: 'Imię i nazwisko',
        value: 'name',
      },
      {
        text: 'Suma',
        value: 'totalPrice',
      },
      {
        text: 'Status',
        value: 'orderStatus',
      },
      {
        text: 'Zapłacone?',
        value: 'payment',
      },
      {
        text: 'List?',
        value: 'packageId',
      },
      {
        sortable: false,
        text: '',
        value: 'actions',
      },
    ];

    items = []
    loading = true
    totalRows = 0
    dialog = false
    itemToDelete = null

    deliveryMethods = {
      INPOST_COURIER: 0,
      INPOST_PARCEL_LOCKER: 1
    }

    paymentMethods = {
      ONLINE: 0,
      CASH_ON_DELIVERY: 1,
      TRANSFER: 2
    }

    orderStatuses = {
      CREATED: 0,
      READY: 1,
      SENT: 2,
      DELIVERED: 3,
      CANCELLED: 4,
      DRAFT: 5
    }

    paymentStatuses = {
      NEW_PAYMENT: 0,
      TOKEN_REQUESTED: 1,
      FAILED: 2,
      STARTED: 3,
      PAID: 4
    }

    filters = [
      {
        value: '',
        type: 'checkbox',
        label: 'Pokaż również zamówienia w trakcie',
        name: 'inProgress',
        style_columns: '6'
      }
    ]

    onEditItem(item) {
      this.$router.push('/order/' + item.id)
    }

    async loadData() {
      this.loading = true
      const url = '/admin/order-list/'
        + this.buildListParam()
        + this.$refs.filter.buildFilterUrlString()
      const result = await api.get(url)
      if (!result || !result.data.success) {
        alert('Błąd podczas próby pobrania listy zamówień.')
        return
      }

      this.items = result.data.orders?.map(order => ({
        id: order.id,
        orderId: order.orderId,
        serverTime: order.serverTime,
        name: order.billing.name,
        totalPrice: order.totalPrice,
        payment: this.getPaymentMethod(order),
        packageId: order.delivery.packageId,
        orderStatus: this.getStatus(order.status),
      })) || []

      this.totalRows = result.data.pagination.totalRows
      this.loading = false
    }

    getPaymentMethod(order) {
      let paymentType
      switch (order.payment.method) {
        case this.paymentMethods.TRANSFER:
          paymentType = 'Przelew'
          break;
        case this.paymentMethods.CASH_ON_DELIVERY:
          paymentType = 'Przy odbiorze'
          break;
        case this.paymentMethods.ONLINE:
          paymentType = 'przelewy24.pl'
          break;
        default:
          paymentType = `Nieznana metoda płatności: ${method}`
      }

      let result = ''
      if (order.payment.status === this.paymentStatuses.PAID) {
        result = 'Zapłacone: ' + paymentType
      } else if (
          order.payment.method === this.paymentMethods.CASH_ON_DELIVERY
          || order.payment.method === this.paymentMethods.TRANSFER) {
        result = 'Do zapłaty: ' + paymentType
      } else {
        result = 'oczekuje'
      }

      return result
    }

    getDeliveryMethod(method) {
      let result
      switch (method) {
        case this.deliveryMethods.INPOST_COURIER:
          result = 'Kurier'
          break;
        case this.deliveryMethods.INPOST_PARCEL_LOCKER:
          result = 'Paczkomat'
          break;
        default:
          result = method
      }
      return result
    }

    getStatus(status) {
      let result
      switch (status) {
        case this.orderStatuses.DRAFT:
          result = 'W trakcie tworzenia'
          break;
        case this.orderStatuses.CREATED:
          result = 'Nowe'
          break;
        case this.orderStatuses.READY:
          result = 'Przygotowane'
          break;
        case this.orderStatuses.SENT:
          result = 'Wysłane'
          break;
        case this.orderStatuses.DELIVERED:
          result = 'Dostarczone'
          break;
        case this.orderStatuses.CANCELLED:
          result = 'Anulowane'
          break;
        default:
          result = status
      }
      return result
    }

    getColor(orderStatus) {
      if (orderStatus === 'Dostarczone') {return 'green'}
      else if (orderStatus === 'Wysłane') {return 'orange'}
      else if (orderStatus === 'Przygotowane') {return 'yellow'}
      else if (orderStatus === 'Anulowane') {return '#eee'}
      return 'white'
    }

    getTextColor(orderStatus) {
      if (orderStatus === 'Dostarczone') {return 'white'}
      else if (orderStatus === 'Wysłane') {return 'white'}
      else if (orderStatus === 'Przygotowane') {return 'black'}
      else if (orderStatus === 'Anulowane') {return 'black'}
      return 'black'
    }

    onDeleteItem(item) {
      this.itemToDelete = item
      this.dialog = true
    }

    async onDelete() {
      if (this.itemToDelete) {
        this.loading = true
        const url = '/admin/order/' + this.itemToDelete.id
        const result = await api.delete(url)
        if (!result || !result.data.success) {
          alert('Błąd podczas próby usunięcia zamówienia.')
          this.loading = false
          return
        }
        this.dialog = false
        this.itemToDelete = null
        await this.loadData()
      }
    }
  }
</script>

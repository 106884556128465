export const femaleColorValues = [
    { value: 'black', text: 'czarne' },
    { value: 'blond', text: 'blond' },
    { value: 'brown', text: 'brązowe' },
    { value: 'dark_brown', text: 'ciemnobrązowe' },
    { value: 'other', text: 'pozostałe' },
    { value: 'red', text: 'kasztanowe' },
]

export const femaleStyleValues = [
    { value: 'bun', text: 'kok' },
    { value: 'mid_back', text: 'długie' },
    { value: 'neck', text: 'krótkie' },
    { value: 'shoulder', text: 'do ramion' },
    { value: 'tie', text: 'warkocze' },
    { value: 'upper_back', text: 'średniej długości' },
]

export const maleColorValues = [
    { value: 'black', text: 'czarne' },
    { value: 'blond', text: 'blond' },
    { value: 'brown', text: 'brązowe' },
    { value: 'dark_brown', text: 'ciemnobrązowe' },
    { value: 'other', text: 'pozostałe' },
    { value: 'red', text: 'kasztanowe' },
]

import { Vue, Component } from 'vue-property-decorator';
import {adminModule as store} from "@/app/store";
import {
    LIST_NUMBER_OF_ROWS,
    LIST_PAGE_NUMBER,
    LIST_SEARCH
} from "@/app/store/getter-types";
import {
    SET_LIST_NUMBER_OF_ROWS,
    SET_LIST_PAGE_NUMBER,
    SET_LIST_SEARCH
} from "@/app/store/mutation-types";

@Component
export default class ListStore extends Vue {
    @store.Getter(LIST_PAGE_NUMBER) pageNumber;
    @store.Getter(LIST_NUMBER_OF_ROWS) numberOfRows;
    @store.Getter(LIST_SEARCH) searchText;
    @store.Mutation(SET_LIST_PAGE_NUMBER) setPageNumber;
    @store.Mutation(SET_LIST_NUMBER_OF_ROWS) setNumberOfRows;
    @store.Mutation(SET_LIST_SEARCH) setSearchText;

    search = ''
    options = {
        page: 1,
        itemsPerPage: 10,
    }

    timeout = null

    buildListParam() {
        return this.options.page + '/'
            + this.options.itemsPerPage + '?'
            + 'sort_by=' + this.options.sortBy.join(',') + '&'
            + 'sort_desc=' + this.options.sortDesc.map(item => item ? 1 : 0).join(',') + '&'
            + 'search=' + (this.search || '')
    }

    onUpdate() {
        if (this.timeout === null) {
            this.timeout = setTimeout(() => {
                this.timeout = null
                this.loadData()
            }, 500)
        }
    }

    onUpdatePage(pageNumber) {
        if (!isNaN(+pageNumber)) {
            this.setPageNumber({ viewName: this.viewName, pageNumber })
        }
    }

    onUpdateItemsPerPage(itemsPerPage) {
        if (!isNaN(+itemsPerPage)) {
            this.setNumberOfRows({ viewName: this.viewName, numberOfRows: itemsPerPage })
        }
    }

    onSearch() {
        this.setSearchText({ viewName: this.viewName, search: this.search })
        this.loadData()
    }

    mounted() {
        if (!this.viewName) {
            console.log('This view have no "viewName" property set.')
            return
        }

        if (this.pageNumber[this.viewName]) {
            this.options.page = this.pageNumber[this.viewName]
        } else {
            this.options.page = 1
        }
        if (this.numberOfRows[this.viewName]) {
            this.options.itemsPerPage = this.numberOfRows[this.viewName]
        } else {
            this.options.itemsPerPage = 10
        }
        if (this.searchText[this.viewName]) {
            this.search = this.searchText[this.viewName]
        }
    }
}

import axios from 'axios';
import router from "@/router";

export function leadingSlash(str) {
    return str.startsWith('/') ? str : '/' + str
}

export function trailingSlash(str) {
    return str.endsWith('/') ? str : str + '/'
}

export const wait = timeout => {
    return new Promise(resolve => setTimeout(resolve, timeout))
}


const apiUrl = process.env.VUE_APP_API_URL

export const reauthorize = async () => {
    const credentials = localStorage.getItem('credentials')
    try {
        const result = await axios.get(`${apiUrl}/admin/auth`, {
            headers: {
                Authorization: `Basic ${credentials}`,
            },
        });
        if (result && result.data.success) {
            localStorage.setItem('token', result.data.token);
            return true
        }
    } catch (e) {
        console.log(e)
    }

    return false
}

export const logout = () => {
    localStorage.removeItem('token')
    localStorage.removeItem('credentials')
}

const fetch = async (fnName, url, options, data = {}) => {
    if (!options) {
        options = {}
    }
    if (!options.headers) {
        options.headers = {}
    }
    options.validateStatus = () => true
    let result
    axios.defaults.timeout = 0

    let iterator = 2
    while (iterator-- > 0) {
        try {
            const token = localStorage.getItem('token')
            if (token) {
                options.headers.Authorization = `Bearer ${token}`
            }
            switch (fnName) {
                case axios.get:
                case axios.delete:
                    result = await fnName(apiUrl + url, options)
                    break;
                case axios.post:
                case axios.patch:
                    result = await fnName(apiUrl + url, data, options)
                    break;
            }
            if (result && result.status === 401) {
                localStorage.setItem(token, '')
                if (!(await reauthorize())) {
                    console.log('Nie powiodła się ponowna autoryzacja.')
                    router.push({name: 'Login'})
                    break
                }
                continue
            }
            break
        } catch (e) {
            console.log(e)
            result = null
            break
        }
    }

    return result
}

export const api = {
    async get(url, options) {
        return fetch(axios.get, url, options)
    },
    async post(url, data, options) {
        return fetch(axios.post, url, options, data)
    },
    async patch(url, data, options) {
        return fetch(axios.patch, url, options, data)
    },
    async delete(url, options) {
        return fetch(axios.delete, url, options)
    },
}

<template>
  <v-container
    id="data-tables-view"
    fluid
    tag="section"
  >
    <v-row class="mb-10">
      <v-col>
        <app-btn
          color="default"
          class="mr-2 mb-2"
          @click="loadData"
        >
          <v-icon color="darken-2">
            mdi-refresh
          </v-icon>
        </app-btn>
        <v-btn
          color="default"
          class="mb-2 mr-2"
          @click="onAddNewItem"
        >
          Dodaj nowy element
        </v-btn>
        <v-btn
          color="default"
          class="mb-2"
          @click="onGenerate"
        >
          Generuj stronę kodów
        </v-btn>
      </v-col>
    </v-row>
    <material-card
      icon="mdi-vuetify"
      icon-small
      color="accent"
      title="Zniżki"
    >
      <v-card-text>
        <v-text-field
          v-model="search"
          append-icon="mdi-magnify"
          class="ml-auto"
          hide-details
          label="Search records"
          single-line
          style="max-width: 250px;"
          @change="onSearch"
        />

        <v-divider class="mt-3" />

        <v-data-table
          :items-per-page="options.itemsPerPage"
          :headers="headers"
          :items="items"
          :search.sync="search"
          :sort-by="['validTo', 'validFrom']"
          :sort-desc="['validTo', 'validFrom']"
          multi-sort
          :loading="loading"
          loading-text="Ładuję dane, proszę czekać..."
          :options.sync="options"
          :server-items-length="totalRows"
          :footer-props="{
            showFirstLastPage: true,
            firstIcon: 'mdi-arrow-collapse-left',
            lastIcon: 'mdi-arrow-collapse-right',
            prevIcon: 'mdi-minus',
            nextIcon: 'mdi-plus'
          }"
          @update:options="onUpdate"
          @update:page="onUpdatePage"
          @update:items-per-page="onUpdateItemsPerPage"
        >
          <template #[`item.src`]="{ item }">
            <v-img
              :src="item.src"
              class="mt-2 mb-2"
              max-height="90"
              contain
            />
          </template>
          <template #[`item.actions`]="{ item }">
            <v-icon
              class="mr-2"
              @click="onEditItem(item)"
            >
              mdi-pencil
            </v-icon>
            <v-icon @click="onDeleteItem(item)">
              mdi-delete
            </v-icon>
          </template>
        </v-data-table>
      </v-card-text>
    </material-card>

    <v-dialog
      v-model="dialog"
      width="500"
    >
      <v-card>
        <v-card-title class="text-h5 grey lighten-2">
          Na pewno usunąć zniżkę?
        </v-card-title>

        <v-divider />

        <v-card-actions>
          <v-spacer />
          <v-btn
            color="primary"
            text
            @click="onDelete"
          >
            Tak
          </v-btn>
          <v-btn
            color="primary"
            text
            @click="dialog = false"
          >
            Nie
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-container>
</template>

<script>
import { Component, Mixins } from "vue-property-decorator";
import ListStore from "@/app/mixins/listStore";

import { api } from "@/util/helpers";
import moment from "moment";

@Component
export default class Discounts extends Mixins(ListStore) {
  viewName = 'discounts'

  headers = [
    {
      text: 'Kod',
      value: 'code',
      sortable: false
    },
    {
      text: 'Ważny od',
      value: 'validFrom'
    },
    {
      text: 'Ważny do',
      value: 'validTo'
    },
    {
      text: 'Reguła',
      value: 'rule'
    },
    {
      text: 'Liczba',
      value: 'volume'
    },
    {
      sortable: false,
      text: '',
      value: 'actions'
    }
  ];

  items = [];
  loading = true;
  totalRows = 0;
  dialog = false;
  itemToDelete = null;

  async onGenerate() {
    if (confirm('Na pewno chcesz wygenerować całą stronę kodów?')) {
      this.loading = true;
      const url = '/admin/discount/generate';
      const result = await api.post(url, {
        count: 28 * 7,
        discount: {
          code: '',
          validFrom: moment().format(),
          validTo: moment().format(),
          type: 0,
          volume: 1,
          rule: {
            minValue: 0,
            percentage: 30
          }
        },
        campaignName: moment().format('YYYY-MM-DD HH:mm')
      });
      if (!result || !result.data.success) {
        alert('Błąd podczas próby wygenerowania strony zniżek.');
        this.loading = false;
        return;
      }
      this.dialog = false;
      window.open(result.data.path)
      await this.loadData();
    }
  }

  onEditItem(item) {
    this.$router.push('/discount/' + item.id);
  }

  onDeleteItem(item) {
    this.itemToDelete = item;
    this.dialog = true;
  }

  async onDelete() {
    if (this.itemToDelete) {
      this.loading = true;
      const url = '/admin/discount/' + this.itemToDelete.id;
      const result = await api.delete(url);
      if (!result || !result.data.success) {
        alert('Błąd podczas próby usunięcia zniżki.');
        this.loading = false;
        return;
      }
      this.dialog = false;
      this.itemToDelete = null;
      await this.loadData();
    }
  }

  onAddNewItem() {
    this.$router.push('/discount/0');
  }

  async loadData() {
    this.loading = true;
    const url = '/admin/discount/' + this.buildListParam()
    const result = await api.get(url);
    if (!result || !result.data.success) {
      alert('Błąd podczas próby pobrania danych.');
      return;
    }

    this.items = result.data.discounts.map(discount => ({
      id: discount.id,
      code: discount.code,
      validFrom: moment(discount.validFrom).format('YYYY-MM-DD'),
      validTo: moment(discount.validTo).format('YYYY-MM-DD'),
      rule: JSON.stringify(discount.rule),
      volume: discount.volume
    }));

    this.totalRows = result.data.pagination.totalRows;
    this.loading = false;
  }
}
</script>

<template>
  <v-container
    id="regular-forms-view"
    fluid
    tag="section"
  >
    <v-row>
      <v-col
        cols="12"
        md="6"
      >
        <app-btn
          color="default"
          class="mr-2 mb-2"
          to="/boy-hairstyles"
        >
          <v-icon>
            mdi-keyboard-return
          </v-icon>
        </app-btn>
        <app-btn
          color="default"
          class="mr-2 mb-2"
          @click="save"
        >
          {{ id === '0' ? 'Dodaj fryzurę' : 'Zapisz zmiany' }}
        </app-btn>
        <app-btn
          color="default"
          class="mr-2 mb-2"
          @click="saveAndClose"
        >
          {{ id === '0' ? 'Dodaj fryzurę i zamknij' : 'Zapisz zmiany i zamknij' }}
        </app-btn>
      </v-col>
    </v-row>

    <v-row>
      <v-col
        cols="12"
        md="6"
      >
        <material-card
          heading="Podstawowe dane"
          color="accent"
        >
          <v-card-text>
            <v-select
              v-model="hairstyleData.color"
              :items="boyColorValues"
              label="Kolor"
            />
            <v-file-input
              id="file"
              ref="file"
              accept="image/png"
              label="Dodaj/zamień zdjęcie"
              prepend-icon="mdi-camera"
              @change="handleFileUpload"
            />
          </v-card-text>
        </material-card>
      </v-col>
      <v-col
        v-if="id !== '0'"
        :key="reloadKey"
        cols="12"
        md="6"
        style="position: relative"
      >
        <img
          style="position: absolute; top: 0; left: 0; zoom: 0.615"
          :src="hairstyleData.sampleBodyUrl"
        >
        <img
          style="position: absolute; top: 0; left: 0;"
          :src="hairstyleData.sourceUrl"
          alt="Podgląd obrazu włosów"
        >
      </v-col>
    </v-row>

    <ProgressPopup
      v-if="saveLoader"
      text="Zapisuję..."
    />
  </v-container>
</template>

<script>
import { Vue, Component, Prop } from 'vue-property-decorator';
import { api } from "@/util/helpers";
import { maleColorValues } from '@/app/common/Maps';
import ProgressPopup from "@/app/common/ProgressPopup";

@Component({
  components: {ProgressPopup}
})
export default class BoyHairstyleEdit extends Vue {
  @Prop(String) id

  zoom = 0
  saveLoader = false
  file = null
  reloadKey = 0
  hairstyleData = {
    name: '',
    fixX: 0,
    fixY: 0,
    sourceUrl : '',
    scale: 0,
  }

  boyColorValues = maleColorValues

  async mounted() {
    await this.fetchDog(this.id)
  }

  async fetchDog(boyHairstyleId) {
    if (boyHairstyleId !== "0") {
      const result = await api.get('/admin/boy-hairstyle/' + boyHairstyleId)
      if (result && result.data.success) {
        this.hairstyleData = result.data
      }
    }
  }

  zoomIn() {
    this.zoom = (this.zoom + 10) || 100
  }

  handleFileUpload(file) {
    this.file = file;
  }

  async saveAndClose() {
    if (await this.save()) {
      await this.$router.push('/boy-hairstyles')
    }
  }

  async save() {
    this.saveLoader = true

    let formData = new FormData();
    formData.append("color", this.hairstyleData.color);
    formData.append("newImage", this.file);

    let result
    if (this.id !== "0") {
      result = await api.patch('/admin/boy-hairstyle/' + this.hairstyleData.id, formData, {
        'Content-Type': 'multipart/form-data'
      })
    } else {
      result = await api.post('/admin/boy-hairstyle', formData, {
        'Content-Type': 'multipart/form-data'
      })
    }
    if (!result || !result.data.success) {
      alert('Błąd podczas zapisu zmian')
      console.log(result)
      this.saveLoader = false
      return false
    }

    this.saveLoader = false
    if (this.id === "0") {
      this.$router.push('/boy-hairstyle/' + result.data.id)
    } else {
      this.hairstyleData.sourceUrl = result.data.sourceUrl
      this.reloadImage()
    }
    return true
  }

  reloadImage() {
    this.reloadKey++
  }

  zoomOut() {
    this.zoom = (this.zoom - 10) || 0
  }
}
</script>

<template>
  <v-row>
    <v-col>
      <material-card
        heading="Zarządzanie buforem"
        color="accent"
      >
        <v-card-text>
          <app-btn
            :color="cacheStatusColor"
            class="mr-2 mb-2"
            @click="clearCache"
          >
            Wyczyść bufor
          </app-btn>
        </v-card-text>
      </material-card>
      <v-dialog
        v-model="saveLoader"
        :hide-overlay="false"
        persistent
        width="300"
      >
        <v-card
          color="primary"
          dark
        >
          <v-card-text>
            Zapisuję...
            <v-progress-linear
              indeterminate
              color="white"
              class="mb-0"
            />
          </v-card-text>
        </v-card>
      </v-dialog>
    </v-col>
  </v-row>
</template>

<script>
import { Vue, Component, Prop } from 'vue-property-decorator';
import { api } from "@/util/helpers";

@Component
export default class ClearCache extends Vue {
  saveLoader = false
  cacheStatusColor = 'default'

  async clearCache() {
    this.saveLoader = true
    await api.delete('/admin/cache')
    setTimeout(() => { this.saveLoader = false }, 500)
    this.cacheStatusColor = 'success'
  }
}
</script>

<style scoped>

</style>

import {
  LIST_PAGE_NUMBER,
  LIST_NUMBER_OF_ROWS,
  LIST_SEARCH,
  LIST_FILTERS,
} from './getter-types';

export default {
  /** @return {Object} */
  [LIST_PAGE_NUMBER]: state => state.list.pageNumber,
  /** @return {Object} */
  [LIST_NUMBER_OF_ROWS]: state => state.list.numberOfRows,
  /** @return {Object} */
  [LIST_SEARCH]: state => state.list.search,

  [LIST_FILTERS]: state => state.listFilters
};

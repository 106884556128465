<template>
  <v-dialog
    v-model="showLoader"
    :hide-overlay="false"
    persistent
    width="300"
  >
    <v-card
      color="primary"
      dark
    >
      <v-card-text>
        {{ text }}
        <v-progress-linear
          indeterminate
          color="white"
          class="mb-0"
        />
      </v-card-text>
    </v-card>
  </v-dialog>
</template>

<script>
  import {Vue, Component, Prop} from 'vue-property-decorator';

  @Component
  export default class ProgressPopup extends Vue {
    @Prop(String, "Pracuję...") text

    showLoader = true
  }
</script>

<style scoped>

</style>

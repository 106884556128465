import {
  SET_LIST_PAGE_NUMBER,
  SET_LIST_NUMBER_OF_ROWS,
  SET_LIST_SEARCH,
  SET_LIST_FIlTER,
} from './mutation-types';

export default {
  [SET_LIST_PAGE_NUMBER](state, { viewName, pageNumber }) {
    if (pageNumber > 0) {
      state.list.pageNumber[viewName] = pageNumber;
    }
  },
  [SET_LIST_NUMBER_OF_ROWS](state, { viewName, numberOfRows }) {
    if (typeof numberOfRows === 'number' && numberOfRows > 0) {
      state.list.numberOfRows[viewName] = numberOfRows;
    }
  },
  [SET_LIST_SEARCH](state, { viewName, search }) {
    state.list.search[viewName] = search;
  },

  [SET_LIST_FIlTER](state, { name, filters }) {
    state.listFilters[name] = filters
  }
};

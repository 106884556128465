<template>
  <v-container
    id="regular-forms-view"
    fluid
    tag="section"
  >
    <v-row>
      <v-col
        cols="12"
        md="12"
      >
        <app-btn
          color="default"
          class="mr-2 mb-2"
          to="/orders"
        >
          <v-icon>
            mdi-keyboard-return
          </v-icon>
        </app-btn>
        <app-btn
          color="default"
          class="mr-2 mb-2"
          @click="save"
        >
          Zapisz zmiany
        </app-btn>
        <app-btn
          color="default"
          class="mr-2 mb-2"
          @click="saveAndClose"
        >
          Zapisz zmiany i zamknij
        </app-btn>
        <app-btn
          v-if="orderData.billing.isCompany"
          color="default"
          class="mr-2 mb-2"
          @click="openInvoice"
        >
          Wystaw fakturę
        </app-btn>
        <app-btn
          color="default"
          class="mr-2 mb-2"
          @click="onGetPackageLetter"
        >
          Pobierz list przewozowy
        </app-btn>
      </v-col>
    </v-row>
    <br>

    <v-tabs>
      <v-tab>Przygotowanie zamówienia</v-tab>
      <v-tab>Przesyłka i płatność</v-tab>

      <v-tab-item>
        <br>
        <v-row v-if="isPaymentOk">
          <v-col
            cols="12"
            md="12"
          >
            <v-alert
              type="warning"
            >
              Nie potwierdzono płatności. Nie przygotowuj tego zamówienia.
            </v-alert>
          </v-col>
        </v-row>

        <v-row>
          <v-col
            cols="12"
            md="6"
          >
            <material-card
              heading="Dane zamówienia"
              color="accent"
            >
              <v-card-text>
                <div class="text-h3">
                  Numer zamówienia: <b>{{ orderData.orderId }}</b>
                </div>
                <div class="text-body-1">
                  Data zamówienia: {{ orderData.serverTime }}
                </div>
                <br>
                <v-select
                  v-model="orderData.status"
                  label="Status zamówienia:"
                  :items="statusOptions"
                />
              </v-card-text>
            </material-card>
          </v-col>

          <v-col
            cols="12"
            md="6"
          >
            <material-card
              heading="Komentarze KapKap"
              :color="orderData.internalComments !== '' ? 'success' : 'accent'"
            >
              <v-card-text>
                <v-textarea
                  v-model="orderData.internalComments"
                  label=""
                  :outlined="true"
                />
              </v-card-text>
            </material-card>
          </v-col>
        </v-row>

        <v-row
          v-if="orderData.additionalInfo"
        >
          <v-col>
            <app-card>
              <v-card-text>
                <material-alert
                  color="success  "
                  dark
                  icon="mdi-bell"
                >
                  <b>{{ orderData.additionalInfo }}</b>
                </material-alert>
              </v-card-text>
            </app-card>
          </v-col>
        </v-row>

        <v-row>
          <v-col>
            <material-card
              icon="mdi-clipboard-text"
              icon-small
              title="Produkty"
              color="accent"
            >
              <v-simple-table>
                <thead>
                  <tr>
                    <th class="primary--text">
                      Przód
                    </th>
                    <th class="primary--text">
                      Tył
                    </th>
                    <th class="primary--text">
                      Nazwa
                    </th>
                    <th class="primary--text">
                      Liczba sztuk
                    </th>
                    <th class="primary--text">
                      Cena
                    </th>
                    <th class="primary--text">
                      Połącz i wyjustuj
                    </th>
                    <th class="primary--text">
                      Powiększ czcionkę
                    </th>
                    <th class="text-right primary--text" />
                  </tr>
                </thead>

                <tbody>
                  <tr
                    v-for="product in orderData.products"
                    :key="product.id"
                  >
                    <td>
                      <v-img
                        max-height="90"
                        max-width="180"
                        contain
                        :src="product.frontThumbnail"
                      />
                    </td>
                    <td>
                      <v-img
                        max-height="90"
                        max-width="180"
                        contain
                        :src="product.backThumbnail"
                      />
                    </td>
                    <td>{{ product.title }}</td>
                    <td>{{ product.number }}</td>
                    <td>{{ product.price }}</td>
                    <td>
                      <v-simple-checkbox
                        v-model="product.joinAndCenter"
                      />
                    </td>
                    <td>
                      <v-simple-checkbox
                        v-model="product.enlargeFont"
                      />
                    </td>
                    <td>
                      <v-btn
                        color="default"
                        small
                        @click="download(product.cartItemId)"
                      >
                        pobierz
                      </v-btn>
                    </td>
                  </tr>
                </tbody>
              </v-simple-table>
              <ProgressPopup
                v-if="imageGeneratorLoader"
                text="Generowanie obrazu..."
              />
              <ProgressPopup
                v-if="saveLoader"
                text="Zapisuję..."
              />
            </material-card>
          </v-col>
        </v-row>
      </v-tab-item>

      <v-tab-item>
        <br>
        <v-row>
          <v-col
            cols="12"
            md="6"
          >
            <material-card
              heading="Przesyłka"
              color="accent"
            >
              <v-card-text>
                <v-form>
                  <v-radio-group
                    :row="true"
                    label="Sposób dostawy:"
                    :value="orderData.delivery.method"
                  >
                    <v-radio
                      label="Paczkomat"
                      :value="deliveryMethods.INPOST_PARCEL_LOCKER"
                      @click="setDeliveryMethod(deliveryMethods.INPOST_PARCEL_LOCKER)"
                    />
                    <v-radio
                      label="Kurier"
                      :value="deliveryMethods.INPOST_COURIER"
                      @click="setDeliveryMethod(deliveryMethods.INPOST_COURIER)"
                    />
                  </v-radio-group>
                  <v-select
                    v-if="orderData.delivery.method === deliveryMethods.INPOST_PARCEL_LOCKER"
                    :items="sourceDeliveryMachineId"
                    class="mb-n3"
                    label="Identyfikator paczkomatu,z którego będzie wysyłana paczka"
                  />
                  <v-text-field
                    v-if="orderData.delivery.method === deliveryMethods.INPOST_PARCEL_LOCKER"
                    v-model="orderData.delivery.additionalData.name"
                    class="mb-n3"
                    label="Identyfikator paczkomatu klientki/klienta"
                  />
                  <v-radio-group
                    v-else
                    :row="true"
                    label="Zamów kuriera:"
                    :value="orderData.delivery.orderCourier"
                  >
                    <v-radio
                      label="Tak"
                      :value="true"
                      @click="orderData.delivery.orderCourier=true"
                    />
                    <v-radio
                      label="Nie"
                      :value="false"
                      @click="orderData.delivery.orderCourier=false"
                    />
                  </v-radio-group>
                  <v-text-field
                    v-model="orderData.delivery.packageId"
                    class="mb-n3"
                    label="Identyfikator przesyłki"
                  />
                  <v-select
                    :items="sizeTemplates"
                    placeholder="Wybierz rozmiar na podstawie liczby kartonów lub wpisz własne wymiary"
                    @change="onChangeBoxSizes"
                  />
                  <v-row>
                    <v-col
                      cols="12"
                      sm="6"
                      md="3"
                    >
                      <v-text-field
                        v-model="orderData.delivery.width"
                        class="mb-n3"
                        label="Szerokość (cm)"
                        type="number"
                      />
                    </v-col>
                    <v-col
                      cols="12"
                      sm="6"
                      md="3"
                    >
                      <v-text-field
                        v-model="orderData.delivery.depth"
                        class="mb-n3"
                        label="Głębokość (cm)"
                        type="number"
                      />
                    </v-col>
                    <v-col
                      cols="12"
                      sm="6"
                      md="3"
                    >
                      <v-text-field
                        v-model="orderData.delivery.height"
                        class="mb-n3"
                        label="Wysokość (cm)"
                        type="number"
                      />
                    </v-col>
                  </v-row>
                </v-form>
              </v-card-text>
            </material-card>
          </v-col>
          <v-col
            cols="12"
            md="6"
          >
            <material-card
              heading="Płatności"
              color="accent"
              :class="isPaymentOk"
            >
              <v-card-text>
                <div class="text-h3">
                  Wartość zamówienia: <b>{{ orderData.totalPrice }} {{ orderData.payment.paymentDetails.currency }}</b>
                </div>
                <v-alert
                  v-if="orderData.discount.discountCode"
                  id="discountInfo"
                  type="info"
                >
                  Użyto kodu rabatowego {{ orderData.discount.discountCode }},
                  który zmniejszył kwotę zamówienia o {{ orderData.discount.discountValue }} zł.
                  Oryginalna wartość zamówienia to  {{ orderData.discount.beforeDiscount }} zł.
                </v-alert>
                <v-radio-group
                  :row="true"
                  label="Metoda płatności:"
                  :value="orderData.payment.method"
                >
                  <v-radio
                    label="Przelewy24.pl"
                    :value="paymentMethods.ONLINE"
                    @click="setPaymentMethod(paymentMethods.ONLINE)"
                  />
                  <v-radio
                    label="Za pobraniem"
                    :value="paymentMethods.CASH_ON_DELIVERY"
                    @click="setPaymentMethod(paymentMethods.CASH_ON_DELIVERY)"
                  />
                  <v-radio
                    label="Przelew"
                    :value="paymentMethods.TRANSFER"
                    @click="setPaymentMethod(paymentMethods.TRANSFER)"
                  />
                </v-radio-group>
                <v-radio-group
                  :row="true"
                  label="Status płatności:"
                  :value="orderData.payment.status"
                  :readonly="orderData.payment.method === paymentMethods.ONLINE"
                >
                  <v-radio
                    v-if="orderData.payment.status !== paymentStatuses.STARTED"
                    label="Nieopłacone"
                    :value="paymentStatuses.NEW_PAYMENT"
                    @click="setPaymentStatus(paymentStatuses.NEW_PAYMENT)"
                  />
                  <v-radio
                    v-else-if="orderData.payment.status !== paymentStatuses.NEW_PAYMENT"
                    label="Nieopłacone"
                    :value="paymentStatuses.STARTED"
                    @click="setPaymentStatus(paymentStatuses.STARTED)"
                  />
                  <v-radio
                    label="Niepowodzenie"
                    :value="paymentStatuses.FAILED"
                    @click="setPaymentStatus(paymentStatuses.FAILED)"
                  />
                  <v-radio
                    label="Zapłacono"
                    :value="paymentStatuses.PAID"
                    @click="setPaymentStatus(paymentStatuses.PAID)"
                  />
                </v-radio-group>
              </v-card-text>
            </material-card>
          </v-col>
        </v-row>

        <v-row>
          <v-col
            cols="12"
            md="6"
          >
            <material-card
              heading="Dane dostawy"
              color="accent"
            >
              <v-card-text>
                <v-form>
                  <v-text-field
                    v-model="orderData.delivery.name"
                    class="mb-n3"
                    label="Imię i nazwisko"
                  />
                  <v-text-field
                    v-model="orderData.delivery.zip"
                    class="mb-n3"
                    label="Kod pocztowy"
                  />
                  <v-text-field
                    v-model="orderData.delivery.city"
                    class="mb-n3"
                    label="Miasto"
                  />
                  <v-text-field
                    v-model="orderData.delivery.address"
                    class="mb-n3"
                    label="Ulica"
                  />
                  <v-text-field
                    v-model="orderData.delivery.email"
                    class="mb-n3"
                    label="Adres e-mail"
                  />
                  <v-text-field
                    v-model="orderData.delivery.phone"
                    class="mb-n3"
                    label="Telefon"
                  />
                </v-form>
              </v-card-text>
            </material-card>
          </v-col>

          <v-col
            cols="12"
            md="6"
          >
            <material-card
              heading="Zamawiający"
              color="accent"
            >
              <v-card-text>
                <v-form>
                  <v-radio-group
                    :row="true"
                    label="Typ zamawiającego:"
                    :value="orderData.billing.isCompany"
                  >
                    <v-radio
                      label="Firma"
                      :value="true"
                      @click="orderData.billing.isCompany = true"
                    />
                    <v-radio
                      label="Osoba fizyczna"
                      :value="false"
                      @click="orderData.billing.isCompany = false"
                    />
                  </v-radio-group>
                  <v-text-field
                    v-if="orderData.billing.isCompany"
                    v-model="orderData.billing.taxId"
                    class="mb-n3"
                    label="NIP"
                  />
                  <v-text-field
                    v-if="orderData.billing.isCompany"
                    v-model="orderData.billing.name"
                    class="mb-n3"
                    label="Nazwa firmy"
                  />
                  <v-text-field
                    v-else
                    v-model="orderData.billing.name"
                    class="mb-n3"
                    label="Imię i nazwisko"
                  />
                  <v-text-field
                    v-model="orderData.billing.zip"
                    class="mb-n3"
                    label="Kod pocztowy"
                  />
                  <v-text-field
                    v-model="orderData.billing.city"
                    class="mb-n3"
                    label="Miasto"
                  />
                  <v-text-field
                    v-model="orderData.billing.address"
                    class="mb-n3"
                    label="Ulica"
                  />
                  <v-text-field
                    v-model="orderData.billing.email"
                    class="mb-n3"
                    label="Adres e-mail"
                  />
                  <v-text-field
                    v-model="orderData.billing.phone"
                    class="mb-n3"
                    label="Telefon"
                  />
                </v-form>
              </v-card-text>
            </material-card>
          </v-col>
        </v-row>
      </v-tab-item>
    </v-tabs>

    <v-dialog
      v-model="dialog"
      width="500"
    >
      <v-card>
        <v-card-title class="text-h5 grey lighten-2">
          Wypełnij dane przesyłki
        </v-card-title>

        <v-card-text>
          {{ message }}
        </v-card-text>

        <v-divider />

        <v-card-actions>
          <v-spacer />
          <v-btn
            color="primary"
            text
            @click="dialog = false"
          >
            OK
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-container>
</template>

<script>
  import { Vue, Component, Prop } from 'vue-property-decorator';
  import {api} from "@/util/helpers";
  import ProgressPopup from "@/app/common/ProgressPopup";

  @Component({
    components: {ProgressPopup}
  })
  export default class OrderEdit extends Vue {
    @Prop(String) id

    zoom = 0
    imageGeneratorLoader = false
    saveLoader = false
    dialog = false
    message = ''

    orderData = {
      billing: {},
      delivery: {
        additionalData: {
          name: ''
        }
      },
      payment: {
        paymentDetails: {
          currency: ''
        }
      },
      discount: {
        beforeDiscount: 0,
        discountValue: 0,
        discountCode: ''
      },
      status: 0
    }

    sizeTemplates = [
      {
        text: 'Dowolny',
        value: 0,
      },
      {
        text: '1 paczka',
        value: 1,
        width: 19,
        depth: 13,
        height: 12,
      },
      {
        text: '2 paczki',
        value: 2,
        width: 19,
        depth: 26,
        height: 12,
      },
      {
        text: '3 paczki',
        value: 3,
        width: 19,
        depth: 39,
        height: 12,
      },
      {
        text: '4 paczki',
        value: 4,
        width: 38,
        depth: 26,
        height: 12,
      },
      {
        text: '5 paczek',
        value: 5,
        width: 38,
        depth: 39,
        height: 12,
      },
      {
        text: '6 paczek',
        value: 5,
        width: 38,
        depth: 39,
        height: 12,
      },
    ]

    sourceDeliveryMachineId = [
      {
        text: 'Chorzów Batory CHO08A',
        value: 'CHO08A',
      },
      {
        text: 'Chorzów Stary CHO29M',
        value: 'CHO29M',
      }
    ]

    deliveryMethods = {
      INPOST_COURIER: 0,
      INPOST_PARCEL_LOCKER: 1
    }

    paymentMethods = {
      ONLINE: 0,
      CASH_ON_DELIVERY: 1,
      TRANSFER: 2
    }

    orderStatuses = {
      CREATED: 0,
      READY: 1,
      SENT: 2,
      DELIVERED: 3,
      CANCELLED: 4,
      DRAFT: 5
    }

    paymentStatuses = {
      NEW_PAYMENT: 0,
      TOKEN_REQUESTED: 1,
      FAILED: 2,
      STARTED: 3,
      PAID: 4
    }

    statusOptions = [
      {
        value: this.orderStatuses.DRAFT,
        text: "W trakcie tworzenia"
      },
      {
        value: this.orderStatuses.CREATED,
        text: "Nowe"
      },
      {
        value: this.orderStatuses.READY,
        text: "Przygotowane"
      },
      {
        value: this.orderStatuses.SENT,
        text: "Wysłane"
      },
      {
        value: this.orderStatuses.DELIVERED,
        text: "Dostarczone"
      },
      {
        value: this.orderStatuses.CANCELLED,
        text: "Anulowane"
      }
    ]

    get isPaymentOk() {
      if (
          (
            this.orderData.payment.method === this.paymentMethods.ONLINE
            || this.orderData.payment.method === this.paymentMethods.TRANSFER
          )
          && this.orderData.payment.status !== this.paymentStatuses.PAID
      ) {
        return 'warningBox'
      }

      return ''
    }

    setDeliveryMethod(value) {
      this.orderData.delivery.method = value
    }

    setPaymentMethod(value) {
      this.orderData.payment.method = value
    }

    setPaymentStatus(value) {
      this.orderData.payment.status = value
    }

    async mounted() {
      await this.fetchOrder(this.id)
    }

    async fetchOrder(orderId) {
      const result = await api.get('/admin/order/' + orderId)
      if (result && result.data.success) {
        this.orderData = result.data
        if (this.orderData.delivery.additionalData === null) {
          this.orderData.delivery.additionalData = { name: '' }
        }
      }
    }

    openInvoice() {
      window.open('https://kapkap.fakturownia.pl/invoices/new?kind=vat&zzic=3')
    }

    zoomIn() {
      this.zoom = (this.zoom + 10) || 100
    }

    async saveAndClose() {
      if (await this.save()) {
        await this.$router.push('/orders')
      }
    }

    async save() {
      this.saveLoader = true
      const result = await api.patch('/admin/order/' + this.orderData.id, this.orderData)
      if (!result || !result.data.success) {
        alert('Błąd podczas zapisu zmian w zamówieniu')
        console.log(result)
        return false
      }

      this.saveLoader = false
      return true
    }

    async download(cartItemId) {
      this.imageGeneratorLoader = true
      const data = {
        orderId: this.orderData.id,
        cartItemId,
        joinAndCenter: this.orderData.products.find(p => p.cartItemId === cartItemId)?.joinAndCenter || false,
        enlargeFont: this.orderData.products.find(p => p.cartItemId === cartItemId)?.enlargeFont || false
      }
      const result = await api.post('/admin/generate', data)
      if (result && result.data.success) {
        setTimeout(() => {
          window.open(result.data.cupUrl)
        }, 1000)
      }
      this.imageGeneratorLoader = false
    }

    zoomOut() {
      this.zoom = (this.zoom - 10) || 0
    }

    onChangeBoxSizes(sizeValue) {
      const size = this.sizeTemplates[sizeValue]
      if (size.value) {
        this.orderData.delivery.height = size.height
        this.orderData.delivery.width = size.width
        this.orderData.delivery.depth = size.depth
      }
    }

    async onGetPackageLetter() {
      if (!this.orderData.delivery.width || !this.orderData.delivery.depth || !this.orderData.delivery.height) {
        this.message = 'Uzupełnij dane dotyczące rozmiaru przesyłki.'
        this.dialog = true
        return
      }

      const packageOrderData = {
        delivery_method: this.orderData.delivery.method,
        width: this.orderData.delivery.width,
        depth: this.orderData.delivery.depth,
        height: this.orderData.delivery.height,
        weight: 1,
      }
      if (this.orderData.delivery.method === this.deliveryMethods.INPOST_PARCEL_LOCKER) {
        packageOrderData.delivery_parcel_locker_id = this.orderData.delivery.additionalData.name
      }
      if (this.orderData.delivery.orderCourier) {
        const date = new Date()
        packageOrderData.pickup_courier = {
          date: date.getFullYear() + '-' + (date.getMonth() + 1) + '-' + date.getDate(),
          min_time: '10:00',
          max_time: '18:00',
        }
      }
      const result = await api.post('/admin/order/' + orderId + '/package', packageOrderData)
      if (result && result.data.success) {
        window.open(result.data.link)
      }
    }
  }
</script>

<style lang="sass">
  .warningBox
    border: 5px solid red!important
  #discountInfo
    margin-top: 20px
    font-weight: bold
</style>

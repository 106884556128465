<template>
  <v-container
    id="login-view"
    class="fill-height"
    tag="section"
  >
    <v-row justify="center">
      <v-col cols="12">
        <v-slide-y-transition appear>
          <material-card
            light
            max-width="350"
            rounded
            class="mx-auto"
            color="accent"
            full-header
          >
            <template #heading>
              <div class="text-center pa-5">
                <div class="text-h4 font-weight-bold white--text">
                  Zaloguj się
                </div>
              </div>
            </template>

            <v-card-text class="text-center">
              <v-text-field
                v-model="login"
                class="mt-10"
                color="secondary"
                placeholder="Email..."
                prepend-icon="mdi-email"
              />

              <v-text-field
                v-model="password"
                class="mb-8"
                color="secondary"
                placeholder="Hasło..."
                prepend-icon="mdi-lock-outline"
                type="password"
              />

              <v-btn
                color="accent"
                rounded
                text
                large
                @click="onLogin"
              >
                Zaloguj się
              </v-btn>
            </v-card-text>
          </material-card>
        </v-slide-y-transition>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
  import { Vue, Component } from 'vue-property-decorator';
  import axios from 'axios'
  import {reauthorize} from "@/util/helpers";

  @Component
  export default class Login extends Vue {
    login = ''
    password = ''

    async onLogin() {
      const credentials = btoa(`${this.login}:${this.password}`)
      localStorage.setItem('credentials', credentials)
      if (!await reauthorize()) {
        alert('Wystąpił błąd podczas autoryzacji użytkownika.')
      } else {
        this.$router.push('/orders')
      }
    }
  }
</script>

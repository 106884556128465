<template>
  <v-container
    id="regular-forms-view"
    fluid
    tag="section"
  >
    <v-row>
      <v-col
        cols="12"
        md="6"
      >
        <app-btn
          color="default"
          class="mr-2 mb-2"
          to="/products"
        >
          <v-icon>
            mdi-keyboard-return
          </v-icon>
        </app-btn>
        <app-btn
          color="default"
          class="mr-2 mb-2"
          @click="save"
        >
          {{ id === '0' ? 'Dodaj produkt' : 'Zapisz zmiany' }}
        </app-btn>
        <app-btn
          color="default"
          class="mr-2 mb-2"
          @click="onSaveAndClose"
        >
          {{ id === '0' ? 'Dodaj nowy produkt i zamknij' : 'Zapisz zmiany i zamknij' }}
        </app-btn>
      </v-col>
    </v-row>

    <v-row>
      <v-col
        cols="12"
        md="6"
      >
        <material-card
          heading="Podstawowe dane"
          color="accent"
        >
          <v-card-text>
            <v-text-field
              v-model="productData.title"
              class="mb-n3"
              label="Krótka nazwa"
            />
            <v-text-field
              v-model="productData.longTitle"
              class="mb-n3"
              label="Długa nazwa"
            />
            <v-text-field
              v-model="productData.path"
              class="mb-n3"
              label="Ścieżka"
            />
            <v-text-field
              v-model="productData.price"
              class="mb-n3"
              label="Cena"
              type="number"
            />
            <v-file-input
              id="file"
              ref="file"
              accept="image/png,image/jpeg"
              label="Dodaj/zamień zdjęcie przykładowego kubka"
              prepend-icon="mdi-camera"
              @change="handleSampleImageUpload"
            />
            <v-text-field
              v-model="productData.tags"
              class="mb-n3"
              label="Tagi (oddziel przecinkami)"
            />
          </v-card-text>
        </material-card>
      </v-col>
      <v-col
        v-if="id !== 0"
        :key="`sampleImage-${reloadKey}`"
        cols="12"
        md="6"
      >
        <v-img
          contain
          max-height="300"
          :src="productData.sampleImageURL"
        />
      </v-col>
    </v-row>

    <v-row>
      <v-col
        cols="12"
        md="6"
      >
        <material-card
          heading="Kategorie"
          color="accent"
        >
          <v-card-text>
            <template
              v-for="(category, key) in categories.categories"
            >
              <v-checkbox
                :key="key"
                v-model="categoriesList"
                :value="category.id"
                :label="category.title"
              />
            </template>
          </v-card-text>
        </material-card>
      </v-col>
    </v-row>

    <v-row>
      <v-col
        cols="12"
        md="6"
      >
        <material-card
          heading="Meta data"
          color="accent"
        >
          <v-card-text>
            <v-text-field
              v-model="productData.pageTitle"
              class="mb-n3"
              label="Meta title"
              type="text"
            />
            <v-textarea
              v-model="productData.pageDescription"
              class="mb-n3"
              label="Meta description"
              type="text"
            />
            <v-textarea
              v-model="productData.pageKeywords"
              class="mb-n3"
              label="Meta keywords"
              type="text"
            />
          </v-card-text>
        </material-card>
      </v-col>

      <v-col
        cols="12"
        md="6"
      >
        <material-card
          heading="Teksty opisowe"
          color="accent"
        >
          <v-card-text>
            <v-textarea
              v-model="productData.description"
              class="mb-n3"
              label="Opis SEO na dole strony"
              type="text"
            />
            <v-text-field
              v-model="productData.footerText"
              class="mb-n3"
              label="Stopka strony"
              type="text"
            />
          </v-card-text>
        </material-card>
      </v-col>
    </v-row>

    <v-row>
      <v-col
        cols="12"
        md="6"
      >
        <material-card
          id="full-background"
          heading="Domyślne tło"
          color="accent"
        >
          <v-card>
            <v-row>
              <template
                v-for="(bg, key) in backgrounds"
              >
                <v-col
                  :key="key"
                  class="d-flex child-flex"
                  cols="4"
                >
                  <input
                    v-show="false"
                    :id="`background-${key}`"
                    type="radio"
                    name="full-background"
                    :value="bg.id"
                    :checked="productData.backgroundId === bg.id"
                    @click="onChangeBackground(bg.id)"
                  >
                  <label
                    :for="`background-${key}`"
                  >
                    <v-img
                      contain
                      max-height="90"
                      :src="bg.lowResFilename"
                      aspect-ratio="1"
                    >
                      <template #placeholder>
                        <v-row
                          class="fill-height ma-0"
                          align="center"
                          justify="center"
                        >
                          <v-progress-circular
                            indeterminate
                            color="grey lighten-5"
                          />
                        </v-row>
                      </template>
                    </v-img>
                  </label>
                </v-col>
              </template>
            </v-row>
          </v-card>
        </material-card>
      </v-col>

      <v-col
        cols="12"
        md="6"
      >
        <material-card
          id="available-backgrounds"
          heading="Dostępne tła"
          color="accent"
        >
          <v-card>
            <v-row>
              <template
                v-for="(bg, key) in backgrounds"
              >
                <v-col
                  :key="key"
                  class="d-flex child-flex"
                  cols="4"
                >
                  <input
                    v-show="false"
                    :id="`available-background-${key}`"
                    type="checkbox"
                    :name="`available-background-${key}`"
                    :value="bg.id"
                    :checked="isBackgroundAvailable(bg.id)"
                    @click="onSetBackground(bg.id)"
                  >
                  <label
                    :for="`available-background-${key}`"
                  >
                    <v-img
                      contain
                      max-height="90"
                      :src="bg.lowResFilename"
                      aspect-ratio="1"
                    >
                      <template #placeholder>
                        <v-row
                          class="fill-height ma-0"
                          align="center"
                          justify="center"
                        >
                          <v-progress-circular
                            indeterminate
                            color="grey lighten-5"
                          />
                        </v-row>
                      </template>
                    </v-img>
                  </label>
                </v-col>
              </template>
            </v-row>
          </v-card>
        </material-card>
      </v-col>
    </v-row>

    <v-row>
      <v-col
        cols="12"
        md="6"
      >
        <material-card
          id="male-body"
          heading="Domyślna postać męska"
          color="accent"
        >
          <v-card>
            <v-row>
              <v-col
                v-for="(body, key) in maleBodies.data"
                :key="key"
                class="d-flex child-flex"
                cols="2"
              >
                <input
                  v-show="false"
                  :id="`male-body-${key}`"
                  type="radio"
                  name="male-body"
                  :value="body.id"
                  :checked="productData.defaultMaleBodyId === body.id"
                  @click="onChangeDefaultMaleBody(body.id)"
                >
                <label
                  :for="`male-body-${key}`"
                >
                  <v-img
                    contain
                    max-height="90"
                    :src="`${maleBodies.sourceUrl}/${body.lowResFilename}`"
                    aspect-ratio="1"
                  >
                    <template #placeholder>
                      <v-row
                        class="fill-height ma-0"
                        align="center"
                        justify="center"
                      >
                        <v-progress-circular
                          indeterminate
                          color="grey lighten-5"
                        />
                      </v-row>
                    </template>
                  </v-img>
                </label>
              </v-col>
            </v-row>
          </v-card>
        </material-card>
      </v-col>

      <v-col
        cols="12"
        md="6"
      >
        <material-card
          id="female-body"
          heading="Domyślna postać żeńska"
          color="accent"
        >
          <v-card>
            <v-row>
              <v-col
                v-for="(body, key) in femaleBodies.data"
                :key="key"
                class="d-flex child-flex"
                cols="2"
              >
                <input
                  v-show="false"
                  :id="`female-body-${key}`"
                  type="radio"
                  name="female-body"
                  :value="body.id"
                  :checked="productData.defaultFemaleBodyId === body.id"
                  @click="onChangeDefaultFemaleBody(body.id)"
                >
                <label
                  :for="`female-body-${key}`"
                >
                  <v-img
                    contain
                    max-height="90"
                    :src="`${femaleBodies.sourceUrl}/${body.lowResFilename}`"
                    aspect-ratio="1"
                  >
                    <template #placeholder>
                      <v-row
                        class="fill-height ma-0"
                        align="center"
                        justify="center"
                      >
                        <v-progress-circular
                          indeterminate
                          color="grey lighten-5"
                        />
                      </v-row>
                    </template>
                  </v-img>
                </label>
              </v-col>
            </v-row>
          </v-card>
        </material-card>
      </v-col>
    </v-row>

    <ProgressPopup
      v-if="saveLoader"
      text="Zapisuję..."
    />
  </v-container>
</template>

<script>
import {Vue, Component, Prop} from 'vue-property-decorator';
import {api} from "@/util/helpers";
import MaterialCard from "@/components/MaterialCard";
import ProgressPopup from "@/app/common/ProgressPopup";

@Component({
  components: {ProgressPopup, MaterialCard}
})
export default class ProductEdit extends Vue {
  @Prop(String) id

  zoom = 0
  saveLoader = false
  productData = {}
  backgrounds = {}
  maleBodies = {}
  femaleBodies = {}
  categories = {} // categories from db
  reloadKey = 0
  categoriesList = [] // categories' checkbox list
  sampleImage = null

  async mounted() {
    await this.fetchProduct(this.id)
  }

  async fetchProduct(productId) {
    let result

    if (productId !== "0") {
      result = await api.get('/admin/product/' + productId)
      if (result && result.data.success) {
        this.productData = result.data
        if (!this.productData.categories) {
          this.productData.categories = []
        }
      }
    }
    result = await api.get('/admin/backgrounds/1/-1')
    if (result && result.data.success) {
      this.backgrounds = result.data.backgrounds
    }
    result = await api.get('/admin/male-bodies/1/-1')
    if (result && result.data.success) {
      this.maleBodies = result.data
    }
    result = await api.get('/admin/female-bodies/1/-1')
    if (result && result.data.success) {
      this.femaleBodies = result.data
    }
    result = await api.get('/admin/categories/1/-1?sort_by=position')
    if (result && result.data.success) {
      this.categories = result.data
      this.categoriesList = this.categories
        .categories.map(category => category.id)
        .filter(categoryId => this.productData.categories.find(productCategoryId => productCategoryId === categoryId))
    }
  }

  zoomIn() {
    this.zoom = (this.zoom + 10) || 100
  }

  async onSaveAndClose() {
    if (await this.save()) {
      await this.$router.push('/products')
    }
  }

  async save() {
    this.saveLoader = true

    let formData = new FormData();
    formData.append("title", this.productData.title || '')
    formData.append("longTitle", this.productData.longTitle || '')
    formData.append("path", this.productData.path || '')
    formData.append("price", `${+this.productData.price}`)
    formData.append("sampleImage", this.sampleImage)
    formData.append("pageTitle", this.productData.pageTitle || '')
    formData.append("pageDescription", this.productData.pageDescription || '')
    formData.append("description", this.productData.description || '')
    formData.append("footerText", this.productData.footerText || '')
    formData.append("pageKeywords", this.productData.pageKeywords || '')
    formData.append("categories", this.categoriesList.join(','))
    formData.append("backgroundId", this.productData.backgroundId)
    formData.append("defaultFemaleBodyId", this.productData.defaultFemaleBodyId)
    formData.append("defaultMaleBodyId", this.productData.defaultMaleBodyId)
    formData.append("availableBackgrounds", (this.productData.availableBackgrounds || []).join(','))
    formData.append("tags", this.productData.tags || '')

    let result
    if (this.id !== "0") {
      result = await api.patch('/admin/product/' + this.productData.id, formData, {
        'Content-Type': 'multipart/form-data'
      })
    } else {
      result = await api.post('/admin/product', formData, {
        'Content-Type': 'multipart/form-data'
      })
    }
    if (!result || !result.data.success) {
      alert('Błąd podczas zapisu zmian')
      this.saveLoader = false
      return false
    }

    this.saveLoader = false
    if (this.id === "0") {
      await this.$router.push('/product/' + result.data.id)
    } else {
      this.productData = result.data
      this.reloadImage()
    }
    return true
  }

  zoomOut() {
    this.zoom = (this.zoom - 10) || 0
  }

  reloadImage() {
    this.reloadKey++
  }

  onChangeDefaultFemaleBody(bodyId) {
    this.productData.defaultFemaleBodyId = bodyId
  }

  onChangeDefaultMaleBody(bodyId) {
    this.productData.defaultMaleBodyId = bodyId
  }

  onChangeBackground(backgroundId) {
    this.productData.backgroundId = backgroundId
  }

  handleSampleImageUpload(file) {
    this.sampleImage = file
  }

  onSetBackground(backgroundId) {
    if (!Array.isArray(this.productData.availableBackgrounds)) {
      this.productData.availableBackgrounds = [backgroundId]
      return
    }

    const pos = this.productData.availableBackgrounds.indexOf(backgroundId)
    if (pos === -1) {
      this.productData.availableBackgrounds.push(backgroundId)
    } else {
      this.productData.availableBackgrounds.splice(pos, 1)
    }
  }

  isBackgroundAvailable(backgroundId) {
    return (this.productData.availableBackgrounds || []).includes(backgroundId)
  }
}
</script>

<style lang="sass" scoped>
  #male-body, #female-body, #full-background, #available-backgrounds, #partial-background
    label
      position: relative
      cursor: pointer

  #male-body, #female-body, #full-background, #available-backgrounds, #partial-background
    label::before
      top: calc(50% - 12px)
      left: 38%
      background-color: white
      color: white
      content: " "
      display: block
      border-radius: 50%
      position: absolute
      width: 25px
      height: 25px
      text-align: center
      line-height: 25px
      transition-duration: 0.4s
      -webkit-transform: scale(0)

  #male-body, #female-body, #full-background, #available-backgrounds, #partial-background
    input:checked + label::before
      content: "\2713"
      background-color: #3bb527
      border: 1px solid #fff
      -webkit-transform: scale(1)
      transform: scale(1)
      left: 38%
      margin-left: auto
      margin-right: auto
      z-index: 10
</style>

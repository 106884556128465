import { namespace } from 'vuex-class';

import getters from './getters';
import mutations from './mutations';
import actions from './actions';

export default {
  namespaced: true,
  state: {
    list: {
      pageNumber: {},
      numberOfRows: {},
      search: {}
    },
    listFilters: {}
  },
  getters,
  mutations,
  actions,
};

export const ADMIN_MODULE = 'adminModule';
export const adminModule = namespace(ADMIN_MODULE);

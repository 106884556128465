<template>
  <v-container
    id="regular-forms-view"
    fluid
    tag="section"
  >
    <v-row>
      <v-col
        cols="12"
        md="6"
      >
        <app-btn
          color="default"
          class="mr-2 mb-2"
          to="/backgrounds"
        >
          <v-icon>
            mdi-keyboard-return
          </v-icon>
        </app-btn>
        <app-btn
          color="default"
          class="mr-2 mb-2"
          @click="save"
        >
          {{ id === '0' ? 'Dodaj tło' : 'Zapisz zmiany' }}
        </app-btn>
        <app-btn
          color="default"
          class="mr-2 mb-2"
          @click="onSaveAndClose"
        >
          {{ id === '0' ? 'Dodaj nowe tło i zamknij' : 'Zapisz zmiany i zamknij' }}
        </app-btn>
      </v-col>
    </v-row>

    <v-row>
      <v-col
        cols="12"
        md="6"
      >
        <material-card
          heading="Podstawowe dane"
          color="accent"
        >
          <v-card-text>
            <v-text-field
              v-model="backgroundData.title"
              class="mb-n3"
              label="Nazwa"
            />
            <v-text-field
              v-model="backgroundData.tags"
              class="mb-n3"
              label="Tagi (oddziel przecinkami)"
            />
            <v-file-input
              ref="partial"
              accept="image/png"
              label="Dodaj/zamień zdjęcie tła na jedną stronę"
              prepend-icon="mdi-camera"
              @change="handlePartialImageUpload"
            />
            <v-file-input
              ref="full"
              accept="image/png"
              label="Dodaj/zamień zdjęcie pełnego tła"
              prepend-icon="mdi-camera"
              @change="handleFullImageUpload"
            />
          </v-card-text>
        </material-card>
      </v-col>
      <v-col
        v-if="id !== 0"
        cols="12"
        md="6"
        style="position: relative"
      >
        <v-img
          ref="partialImage"
          :key="`partialImage-${reloadKey}`"
          :src="backgroundData.partialImageUrl"
          style="position: absolute; top: 0; left: 0; border: 1px solid black;"
          @load="partialImageLoaded"
        />
        <div
          :style="partialImageBoxStyle"
        />
      </v-col>
    </v-row>

    <v-row>
      <v-col
        cols="12"
        md="6"
      >
        <material-card
          heading="Strefa nadruku dla połowy szerokości tła"
          color="accent"
        >
          <v-card-text>
            <v-text-field
              v-model="backgroundData.drawableArea.Min.X"
              class="mb-n3"
              label="Lewy górny róg - X"
            />
            <v-text-field
              v-model="backgroundData.drawableArea.Min.Y"
              class="mb-n3"
              label="Lewy górny róg - Y"
            />
            <v-text-field
              v-model="backgroundData.drawableArea.Max.X"
              class="mb-n3"
              label="Prawy dolny róg - X (maks. 2717)"
              type="number"
            />
            <v-text-field
              v-model="backgroundData.drawableArea.Max.Y"
              class="mb-n3"
              label="Prawy dolny róg - Y (maks. 1063)"
            />
          </v-card-text>
        </material-card>
      </v-col>
      <v-col
        v-if="id !== 0"
        cols="12"
        md="6"
        style="position: relative;"
      >
        <v-img
          ref="fullImage"
          :key="`fullImage-${reloadKey}`"
          :src="backgroundData.fullImageUrl"
          style="position: absolute; top: 0; left: 0; border: 1px solid black;"
          @load="fullImageLoaded"
        />
        <div
          :style="fullImageBoxStyle"
        />
      </v-col>
    </v-row>

    <v-row>
      <v-col
        cols="12"
        md="6"
      >
        <material-card
          heading="Strefa nadruku dla pełnej szerokości tła"
          color="accent"
        >
          <v-card-text>
            <v-text-field
              v-model="backgroundData.fullDrawableArea.Min.X"
              class="mb-n3"
              label="Lewy górny róg - X"
            />
            <v-text-field
              v-model="backgroundData.fullDrawableArea.Min.Y"
              class="mb-n3"
              label="Lewy górny róg - Y"
            />
            <v-text-field
              v-model="backgroundData.fullDrawableArea.Max.X"
              class="mb-n3"
              label="Prawy dolny róg - X (maks. 2717)"
              type="number"
            />
            <v-text-field
              v-model="backgroundData.fullDrawableArea.Max.Y"
              class="mb-n3"
              label="Prawy dolny róg - Y (maks. 1063)"
            />
          </v-card-text>
        </material-card>
      </v-col>
    </v-row>

    <ProgressPopup
      v-if="saveLoader"
      text="Zapisuję..."
    />
  </v-container>
</template>

<script>
import {Vue, Component, Prop, Watch} from 'vue-property-decorator';
import {api} from "@/util/helpers";
import ProgressPopup from "@/app/common/ProgressPopup";
import MaterialCard from "@/components/MaterialCard";

@Component({
  components: {ProgressPopup, MaterialCard}
})
export default class BackgroundEdit extends Vue {
  @Prop(String) id

  zoom = 0
  saveLoader = false
  fullImage = null
  partialImage = null
  reloadKey = 0
  backgroundData = {
    title: '',
    drawableArea: {
      Min: { X: 0, Y: 0 },
      Max: { X: 0, Y: 0 },
    },
    fullDrawableArea: {
      Min: { X: 0, Y: 0 },
      Max: { X: 0, Y: 0 },
    }
  }

  partialImageBoxStyle = {
    position: 'absolute',
    border: '1px solid red',
    left: 0,
    top: 0,
    width: 0,
    height: 0,
    backgroundColor: 'yellow',
    opacity: 0.2
  }

  fullImageBoxStyle = {
    position: 'absolute',
    border: '1px solid red',
    left: 0,
    top: 0,
    width: 0,
    height: 0,
    backgroundColor: 'yellow',
    opacity: 0.2
  }

  @Watch('backgroundData.drawableArea', { deep: true })
  onPartialDrawableArea() {
    const drawableArea = this.backgroundData.drawableArea
    const maxWidth = 2717
    const maxHeight = 1063
    const maxX = drawableArea.Max.X <= 0 ? maxWidth : drawableArea.Max.X
    const maxY = drawableArea.Max.Y <= 0 ? maxHeight : drawableArea.Max.Y
    const width = this.$refs?.partialImage?.$el?.clientWidth || 0
    const height = this.$refs?.partialImage?.$el?.clientHeight || 0
    const scaleX = width / maxWidth
    const scaleY = height / maxHeight
    this.partialImageBoxStyle.left = (drawableArea.Min.X * scaleX) + 'px'
    this.partialImageBoxStyle.top =  (drawableArea.Min.Y * scaleY) + 'px'
    this.partialImageBoxStyle.width = (maxX * scaleX - (drawableArea.Min.X * scaleX)) + 'px'
    this.partialImageBoxStyle.height = (maxY * scaleY - (drawableArea.Min.Y * scaleY))  + 'px'
  }

  @Watch('backgroundData.fullDrawableArea', { deep: true })
  onFullDrawableArea() {
    const drawableArea = this.backgroundData.fullDrawableArea
    const maxWidth = 2717
    const maxHeight = 1063
    const maxX = drawableArea.Max.X <= 0 ? maxWidth : drawableArea.Max.X
    const maxY = drawableArea.Max.Y <= 0 ? maxHeight : drawableArea.Max.Y
    const width = this.$refs?.fullImage?.$el?.clientWidth || 0
    const height = this.$refs?.fullImage?.$el?.clientHeight || 0
    const scaleX = width / maxWidth
    const scaleY = height / maxHeight
    this.fullImageBoxStyle.left = (drawableArea.Min.X * scaleX) + 'px'
    this.fullImageBoxStyle.top =  (drawableArea.Min.Y * scaleY) + 'px'
    this.fullImageBoxStyle.width = (maxX * scaleX - (drawableArea.Min.X * scaleX)) + 'px'
    this.fullImageBoxStyle.height = (maxY * scaleY - (drawableArea.Min.Y * scaleY))  + 'px'
  }

  partialImageLoaded () {
    this.onPartialDrawableArea()
  }

  fullImageLoaded () {
    this.onFullDrawableArea()
  }

  async mounted() {
    await this.fetchBackground(this.id)
  }

  async fetchBackground(backgroundId) {
    if (backgroundId !== "0") {
      const result = await api.get('/admin/background/' + backgroundId)
      if (result && result.data.success) {
        this.backgroundData = result.data
        if (!this.backgroundData.drawableArea) {
          this.backgroundData.drawableArea = { Min: {}, Max: {} }
        }
        if (!this.backgroundData.fullDrawableArea) {
          this.backgroundData.fullDrawableArea = { Min: {}, Max: {} }
        }
      }
    }
  }

  handleFullImageUpload(file) {
    this.fullImage = file
  }

  handlePartialImageUpload(file) {
    this.partialImage = file
  }

  zoomIn() {
    this.zoom = (this.zoom + 10) || 100
  }

  async onSaveAndClose() {
    if (await this.save()) {
      await this.$router.push('/backgrounds')
    }
  }

  async save() {
    this.saveLoader = true

    let formData = new FormData();
    formData.append("title", this.backgroundData.title || '')
    formData.append("tags", this.backgroundData.tags || '')
    formData.append("partialMinX", +this.backgroundData.drawableArea.Min.X || 0)
    formData.append("partialMinY", +this.backgroundData.drawableArea.Min.Y || 0)
    formData.append("partialMaxX", +this.backgroundData.drawableArea.Max.X || 0)
    formData.append("partialMaxY", +this.backgroundData.drawableArea.Max.Y || 0)
    formData.append("fullMinX", +this.backgroundData.fullDrawableArea.Min.X || 0)
    formData.append("fullMinY", +this.backgroundData.fullDrawableArea.Min.Y || 0)
    formData.append("fullMaxX", +this.backgroundData.fullDrawableArea.Max.X || 0)
    formData.append("fullMaxY", +this.backgroundData.fullDrawableArea.Max.Y || 0)
    formData.append("fullImage", this.fullImage)
    formData.append("partialImage", this.partialImage)

    let result
    if (this.id !== "0") {
      result = await api.patch('/admin/background/' + this.backgroundData.id, formData, {
        'Content-Type': 'multipart/form-data'
      })
    } else {
      result = await api.post('/admin/background', formData, {
        'Content-Type': 'multipart/form-data'
      })

    }
    setTimeout(() => { this.saveLoader = false }, 500)
    if (!result || !result.data.success) {
      alert('Błąd podczas zapisu zmian w tle')
      console.log(result)
      return false
    }  else {
      if (this.id === '0') {
        await this.$router.push('/background/' + result.data.id)
      } else {
        this.backgroundData = result.data
        this.reloadImage()
      }
    }

    return true
  }

  reloadImage() {
    this.reloadKey++
  }

  zoomOut() {
    this.zoom = (this.zoom - 10) || 0
  }
}
</script>

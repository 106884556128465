<template>
  <v-container
    id="data-tables-view"
    fluid
    tag="section"
  >
    <v-row class="mb-10">
      <v-col
        cols="12"
        md="6"
      >
        <app-btn
          color="default"
          class="mr-2 mb-2"
          @click="loadData"
        >
          <v-icon
            color="darken-2"
          >
            mdi-refresh
          </v-icon>
        </app-btn>
        <v-btn
          color="default"
          class="mb-2"
          @click="onAddNewItem"
        >
          Dodaj nowy kubek
        </v-btn>
      </v-col>
    </v-row>

    <material-card
      icon="mdi-vuetify"
      icon-small
      color="accent"
      title="Kubki"
    >
      <v-card-text>
        <v-text-field
          v-model="search"
          append-icon="mdi-magnify"
          class="ml-auto"
          hide-details
          label="Wyszukaj"
          single-line
          style="max-width: 250px;"
          @change="onSearch"
        />

        <v-divider class="mt-3" />

        <v-data-table
          :items-per-page="options.itemsPerPage"
          :headers="headers"
          :items="items"
          :search.sync="search"
          :sort-by="['position']"
          :sort-desc="[false]"
          multi-sort
          :loading="loading"
          loading-text="Ładuję dane, proszę czekać..."
          :options.sync="options"
          :server-items-length="totalRows"
          :footer-props="{
            showFirstLastPage: true,
            firstIcon: 'mdi-arrow-collapse-left',
            lastIcon: 'mdi-arrow-collapse-right',
            prevIcon: 'mdi-minus',
            nextIcon: 'mdi-plus'
          }"
          @update:options="onUpdate"
          @update:page="onUpdatePage"
          @update:items-per-page="onUpdateItemsPerPage"
        >
          <template #[`item.defaultImage`]="{ item }">
            <v-img
              :src="item.defaultImage"
              class="float-left mt-2 mb-2"
              max-height="90"
              contain
            />
          </template>
          <template #[`item.name`]="{ item }">
            {{ item.name }}<br>{{ item.capacity || '[unknown]' }} ml
          </template>
          <template #[`item.actions`]="{ item }">
            <v-icon
              class="mr-4"
              @click="onEditItem(item)"
            >
              mdi-pencil
            </v-icon>
            <v-icon
              @click="onDeleteItem(item)"
            >
              mdi-delete
            </v-icon>
          </template>
          <template #[`item.position`]="{ item }">
            <v-row
              style="width: 250px"
            >
              <v-col cols="6">
                <v-text-field
                  :value="item.counterForMoving"
                  type="number"
                  solo
                  @change="onCounterChange(item, $event)"
                />
              </v-col>
              <v-col cols="3">
                <v-icon
                  @click="onMoveUp(item)"
                >
                  mdi-arrow-up
                </v-icon>
              </v-col>
              <v-col cols="3">
                <v-icon
                  @click="onMoveDown(item)"
                >
                  mdi-arrow-down
                </v-icon>
              </v-col>
            </v-row>
          </template>
        </v-data-table>
      </v-card-text>
    </material-card>

    <v-dialog
      v-model="dialog"
      width="500"
    >
      <v-card>
        <v-card-title class="text-h5 grey lighten-2">
          Na pewno usunąć kubek?
        </v-card-title>

        <v-card-text>
          <br>
          <v-img
            v-if="itemToDelete !== null"
            :src="itemToDelete.defaultImage"
            class="mt-2 mb-2"
            max-height="180"
            contain
          />
        </v-card-text>

        <v-divider />

        <v-card-actions>
          <v-spacer />
          <v-btn
            color="primary"
            text
            @click="onDelete"
          >
            Tak
          </v-btn>
          <v-btn
            color="primary"
            text
            @click="dialog = false"
          >
            Nie
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-container>
</template>

<script>
  import { Component, Mixins } from 'vue-property-decorator';
  import {api} from "@/util/helpers";
  import ListStore from "@/app/mixins/listStore";
  import MoveRecord from "@/app/mixins/moveRecord";

  @Component
  export default class Cups extends Mixins(ListStore, MoveRecord) {
    viewName = 'cups'

    headers = [
      {
        text: 'Zdjęcie',
        value: 'defaultImage',
        sortable: false,
      },
      {
        text: 'Na stanie?',
        value: 'inStock',
        sortable: false
      },
      {
        text: 'Liczba na stanie',
        value: 'volume',
        sortable: false
      },
      {
        text: 'Domyślny?',
        value: 'isDefault',
        sortable: false
      },
      {
        text: 'Nazwa',
        value: 'name',
      },
      {
        text: 'Koszt',
        value: 'cost',
      },
      {
        sortable: false,
        text: 'Działania',
        value: 'actions',
      },
      {
        sortable: true,
        text: 'Pozycja',
        value: 'position',
      }
    ];

    items = []
    loading = true
    totalRows = 0
    dialog = false
    itemToDelete = null

    mounted () {
      this.setItemType('cup')
    }

    onEditItem(item) {
      this.$router.push('/cup/' + item.id)
    }

    onAddNewItem() {
      this.$router.push('/cup/0')
    }

    onDeleteItem(item) {
      this.itemToDelete = item
      this.dialog = true
    }

    async onDelete() {
      if (this.itemToDelete) {
        this.loading = true
        const url = '/admin/cup/' + this.itemToDelete.id
        const result = await api.delete(url)
        if (!result || !result.data.success) {
          alert('Błąd podczas próby usunięcia kubka.')
          this.loading = false
          return
        }
        this.dialog = false
        this.itemToDelete = null
        await this.loadData()
      }
    }

    async loadData() {
      this.loading = true
      const url = '/admin/cups/' + this.buildListParam()
      const result = await api.get(url)
      if (!result || !result.data.success) {
        alert('Błąd podczas próby pobrania listy kubków.')
        return
      }

      if (result.data.cups !== null) {
        this.items = result.data.cups.map(cup => ({
          id: cup.id,
          name: cup.name,
          capacity: cup.capacity,
          cost: cup.cost + ' zł',
          defaultImage: cup.thumbnailURL,
          position: cup.position || 999,
          counterForMoving: 1,
          inStock: cup.inStock ? 'tak' : 'nie',
          isDefault: cup.isDefault ? 'tak' : 'nie',
          volume: cup.volume || 0
        }))
      }

      this.totalRows = result.data.pagination.totalRows
      this.loading = false
    }
  }
</script>

<style scoped>

</style>

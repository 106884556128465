// Pathify
import { make } from 'vuex-pathify'

// Data
const state = {
  drawer: null,
  drawerImage: null,
  mini: false,
  items: [
    {
      title: 'Zamówienia',
      icon: 'mdi-basket',
      to: '/orders'
    },
    {
      title: 'Kategorie',
      icon: 'mdi-widgets',
      to: '/categories'
    },
    {
      title: 'Produkty',
      icon: 'mdi-coffee',
      to: '/products'
    },
    {
      title: 'Tła',
      icon: 'mdi-image-area',
      to: '/backgrounds'
    },
    {
      title: 'Zwierzęta',
      icon: 'mdi-paw',
      collapsed: true,
      items: [
        {
          title: 'Rasy psów',
          icon: 'mdi-dog-side',
          to: '/dogs'
        },
        {
          title: 'Rasy kotów',
          icon: 'mdi-cat',
          to: '/cats'
        },
        {
          title: 'Króliki',
          icon: 'mdi-rabbit',
          to: '/rabbits'
        },
      ]
    },
    {
      title: 'Ludzie',
      icon: 'mdi-account-multiple',
      collapsed: true,
      items: [
        {
          title: 'Body żeńskie',
          icon: 'mdi-human-female',
          to: '/female-bodies'
        },
        {
          title: 'Body męskie',
          icon: 'mdi-human-male',
          to: '/male-bodies'
        },
        {
          title: 'Body dziewczynek',
          icon: 'mdi-human-female-girl',
          to: '/girl-bodies'
        },
        {
          title: 'Body chłopców',
          icon: 'mdi-human-male-boy',
          to: '/boy-bodies'
        },
        {
          title: 'Włosy żeńskie',
          icon: 'mdi-face-woman-profile',
          to: '/female-hairstyles'
        },
        {
          title: 'Włosy męskie',
          icon: 'mdi-face-man-profile',
          to: '/male-hairstyles'
        },
        {
          title: 'Włosy dziewczynek',
          icon: 'mdi-face-woman',
          to: '/girl-hairstyles'
        },
        {
          title: 'Włosy chłopców',
          icon: 'mdi-face-man',
          to: '/boy-hairstyles'
        },
      ]
    },
    {
      title: 'Cytaty',
      icon: 'mdi-comment-quote',
      to: '/quotes'
    },
    {
      title: 'Kubki',
      icon: 'mdi-coffee-outline',
      to: '/cups'
    },
      {
      title: 'Zniżki',
      icon: 'mdi-percent',
      to: '/discounts'
    },
    {
      title: 'Buforowanie',
      icon: 'mdi-cached',
      to: '/clear-cache'
    },
    // {
    //   title: 'Inne',
    //   icon: 'mdi-widgets',
    //   collapsed: true,
    //   items: [
    //     {
    //       title: 'Dashboard',
    //       icon: 'mdi-view-dashboard',
    //       to: '/',
    //     },
    //     {
    //       title: 'Pages',
    //       icon: 'mdi-image',
    //       items: [
    //         {
    //           title: 'Pricing',
    //           to: '/pages/pricing/',
    //         },
    //         {
    //           title: 'RTL Support',
    //           to: '/pages/rtl/',
    //         },
    //         {
    //           title: 'Timeline',
    //           to: '/components/timeline/',
    //         },
    //         {
    //           title: 'Login Page',
    //           to: '/pages/login/',
    //         },
    //         {
    //           title: 'Register Page',
    //           to: '/pages/register/',
    //         },
    //         {
    //           title: 'Lock Screen Page',
    //           to: '/pages/lock/',
    //         },
    //         {
    //           title: 'User Profile',
    //           to: '/components/profile/',
    //         },
    //         {
    //           title: 'Error Page',
    //           to: '/pages/error/',
    //         },
    //       ],
    //     },
    //     {
    //       title: 'Components',
    //       icon: 'mdi-view-comfy',
    //       items: [
    //         {
    //           title: 'Multi Level Collapse',
    //           items: [{
    //             title: 'Example',
    //           }],
    //         },
    //         {
    //           title: 'Buttons',
    //           to: '/components/buttons/',
    //         },
    //         {
    //           title: 'Grid System',
    //           to: '/components/grid/',
    //         },
    //         {
    //           title: 'Tabs',
    //           to: '/components/tabs/',
    //         },
    //         {
    //           title: 'Notifications',
    //           to: '/components/notifications/',
    //         },
    //         {
    //           title: 'Icons',
    //           to: '/components/icons/',
    //         },
    //         {
    //           title: 'Typography',
    //           to: '/components/typography/',
    //         },
    //       ],
    //     },
    //     {
    //       title: 'Forms',
    //       icon: 'mdi-clipboard-outline',
    //       items: [
    //         {
    //           title: 'Regular Forms',
    //           to: '/forms/regular/',
    //         },
    //         {
    //           title: 'Extended Forms',
    //           to: '/forms/extended/',
    //         },
    //         {
    //           title: 'Valiation Forms',
    //           to: '/forms/validation/',
    //         },
    //         {
    //           title: 'Wizard',
    //           to: '/forms/wizard/',
    //         },
    //       ],
    //     },
    //     {
    //       title: 'Tables',
    //       icon: 'mdi-grid',
    //       items: [
    //         {
    //           title: 'Regular Tables',
    //           to: '/tables/regular/',
    //         },
    //         {
    //           title: 'Extended Tables',
    //           to: '/tables/extended/',
    //         },
    //         {
    //           title: 'DataTables Tables',
    //           to: '/tables/data-tables/',
    //         },
    //       ],
    //     },
    //     {
    //       title: 'Maps',
    //       icon: 'mdi-map-marker',
    //       items: [
    //         {
    //           title: 'Google Maps',
    //           to: '/maps/google/',
    //         },
    //         {
    //           title: 'Fullscreen Map',
    //           to: '/maps/fullscreen/',
    //         },
    //       ],
    //     },
    //     {
    //       title: 'Widgets',
    //       icon: 'mdi-widgets',
    //       to: '/widgets/',
    //     },
    //     {
    //       title: 'Charts',
    //       icon: 'mdi-chart-timeline-variant',
    //       to: '/charts/',
    //     },
    //     {
    //       title: 'Calendar',
    //       icon: 'mdi-calendar-range-outline',
    //       to: '/calendar/',
    //     },
    //   ]
    // }
  ],
}

const mutations = make.mutations(state)

const actions = {
  ...make.actions(state),
  init: async ({ dispatch }) => {
    //
  },
}

const getters = {}

export default {
  namespaced: true,
  state,
  mutations,
  actions,
  getters,
}

<template>
  <v-container
    id="regular-forms-view"
    fluid
    tag="section"
  >
    <v-row>
      <v-col
        cols="12"
        md="6"
      >
        <app-btn
          color="default"
          class="mr-2 mb-2"
          to="/categories"
        >
          <v-icon>
            mdi-keyboard-return
          </v-icon>
        </app-btn>
        <app-btn
          color="default"
          class="mr-2 mb-2"
          @click="save"
        >
          {{ id === '0' ? 'Dodaj kategorię' : 'Zapisz zmiany' }}
        </app-btn>
        <app-btn
          color="default"
          class="mr-2 mb-2"
          @click="onSaveAndClose"
        >
          {{ id === '0' ? 'Dodaj nową kategorię i zamknij' : 'Zapisz zmiany i zamknij' }}
        </app-btn>
      </v-col>
    </v-row>

    <v-row>
      <v-col
        cols="6"
        md="6"
      >
        <material-card
          heading="Podstawowe dane"
          color="accent"
        >
          <v-card-text>
            <v-text-field
              v-model="categoryData.title"
              class="mb-n3"
              label="Krótka nazwa (w menu)"
            />
            <v-text-field
              v-model="categoryData.longTitle"
              class="mb-n3"
              label="Długa nazwa"
            />
            <v-text-field
              v-model="categoryData.path"
              class="mb-n3"
              label="Ścieżka"
            />
            <v-text-field
              v-model="categoryData.tags"
              class="mb-n3"
              label="Tagi (oddziel przecinkami)"
            />
          </v-card-text>
        </material-card>
      </v-col>

      <v-col
        cols="6"
        md="6"
      >
        <material-card
          heading="Opcje"
          color="accent"
        >
          <v-card-text>
            <v-radio-group
              v-model="categoryData.type"
              label="Typ:"
              row
            >
              <v-radio
                label="Kubek"
                value="cup"
              />
              <v-radio
                label="Koszulka"
                value="tshirt"
              />
            </v-radio-group>
            <v-radio-group
              v-model="categoryData.active"
              label="Aktywna:"
              row
            >
              <v-radio
                label="Tak"
                :value="true"
              />
              <v-radio
                label="Nie"
                :value="false"
              />
            </v-radio-group>
          </v-card-text>
        </material-card>
      </v-col>
    </v-row>

    <v-row>
      <v-col
        cols="12"
        md="6"
      >
        <material-card
          heading="Meta data"
          color="accent"
        >
          <v-card-text>
            <v-text-field
              v-model="categoryData.metaTitle"
              class="mb-n3"
              label="Meta title"
            />
            <v-textarea
              v-model="categoryData.metaTags"
              label="Meta tags"
              :outlined="false"
              :solo="false"
            />
            <v-textarea
              v-model="categoryData.metaDescription"
              label="Meta description"
              :outlined="false"
              :solo="false"
            />
          </v-card-text>
        </material-card>
      </v-col>

      <v-col
        cols="12"
        md="6"
      >
        <material-card
          heading="Dodatkowe teksty"
          color="accent"
        >
          <v-card-text>
            <v-textarea
              v-model="categoryData.description"
              label="Opis kategorii na górze strony"
              :outlined="false"
              :solo="false"
            />
            <v-textarea
              v-model="categoryData.seoDescription"
              label="Opis SEO na dole strony"
              :outlined="false"
              :solo="false"
            />
            <v-text-field
              v-model="categoryData.footerText"
              class="mb-n3"
              label="Tekst w stopce"
            />
          </v-card-text>
        </material-card>
      </v-col>
    </v-row>

    <ProgressPopup
      v-if="saveLoader"
      text="Zapisuję..."
    />
  </v-container>
</template>

<script>
import {Vue, Component, Prop} from 'vue-property-decorator';
import { set, get } from 'vuex-module-decorators'
import axios from 'axios'
import {api} from "@/util/helpers";
import ProgressPopup from "@/app/common/ProgressPopup";
import MaterialCard from "@/components/MaterialCard";

@Component({
  components: {ProgressPopup, MaterialCard}
})
export default class CategoryEdit extends Vue {
  @Prop(String) id

  zoom = 0
  saveLoader = false
  categoryData = {
    title: '',
    longTitle: '',
    description: '',
    type: '',
    active: false,
  }

  async created() {
    await this.fetchQuote(this.id)
  }

  async fetchQuote(categoryId) {
    if (categoryId !== "0") {
      const result = await api.get('/admin/category/' + categoryId)
      if (result && result.data.success) {
        this.categoryData = result.data
      }
    }
  }

  zoomIn() {
    this.zoom = (this.zoom + 10) || 100
  }

  async onSaveAndClose() {
    if (await this.save()) {
      await this.$router.push('/categories')
    }
  }

  async save() {
    this.saveLoader = true

    let formData = new FormData();
    formData.append("title", this.categoryData.title || '')
    formData.append("longTitle", this.categoryData.longTitle || '')
    formData.append("path", this.categoryData.path || '')
    formData.append("tags", this.categoryData.tags || '')
    formData.append("description", this.categoryData.description || '')
    formData.append("metaTitle", this.categoryData.metaTitle || '')
    formData.append("metaDescription", this.categoryData.metaDescription || '')
    formData.append("metaTags", this.categoryData.metaTags || '')
    formData.append("seoDescription", this.categoryData.seoDescription || '')
    formData.append("footerText", this.categoryData.footerText || '')
    formData.append("type", this.categoryData.type || '')
    formData.append("active", this.categoryData.active || '')

    let result
    if (this.id !== "0") {
      result = await api.patch('/admin/category/' + this.categoryData.id, formData, {
        'Content-Type': 'multipart/form-data'
      })
    } else {
      result = await api.post('/admin/category', formData, {
        'Content-Type': 'multipart/form-data'
      })

    }
    setTimeout(() => { this.saveLoader = false }, 500)
    if (!result || !result.data.success) {
      alert('Błąd podczas zapisu zmian w kategorii')
      console.log(result)
      return false
    }  else {
      if (this.id === '0') {
        await this.$router.push('/category/' + result.data.id)
      } else {
        this.categoryData = result.data
      }
    }

    return true
  }

  zoomOut() {
    this.zoom = (this.zoom - 10) || 0
  }
}
</script>

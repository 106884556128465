<template>
  <v-container
    id="regular-forms-view"
    fluid
    tag="section"
  >
    <v-row>
      <v-col
        cols="12"
        md="6"
      >
        <app-btn
          color="default"
          class="mr-2 mb-2"
          to="/cats"
        >
          <v-icon
            large
            color="darken-2"
          >
            mdi-keyboard-return
          </v-icon>
        </app-btn>
        <app-btn
          color="default"
          class="mr-2 mb-2"
          @click="save"
        >
          {{ id === '0' ? 'Dodaj rasę' : 'Zapisz zmiany' }}
        </app-btn>
        <app-btn
          color="default"
          class="mr-2 mb-2"
          @click="saveAndClose"
        >
          {{ id === '0' ? 'Dodaj rasę i zamknij' : 'Zapisz zmiany i zamknij' }}
        </app-btn>
      </v-col>
    </v-row>

    <v-row>
      <v-col
        cols="12"
        md="6"
      >
        <material-card
          heading="Dane ogólne dla wszystkich wariantów"
          color="accent"
        >
          <v-card-text>
            <v-text-field
              v-model="catData.breedName.pl"
              class="mb-n3"
              label="Nazwa w języku polskim"
            />
            <v-text-field
              v-model="catData.breedName.en"
              class="mb-n3"
              label="Nazwa w języku angielskim"
            />
            <v-text-field
              v-model="catData.height"
              class="mb-n3"
              label="Wysokość kota podana w centymetrach"
              type="number"
            />
            <v-text-field
              v-model="catData.fixX"
              class="mb-n3"
              label="Korekta pozycji w poziomie (liczba dodatnia - w prawy, ujemna w lewo)"
              type="number"
            />
            <v-text-field
              v-model="catData.fixY"
              class="mb-n3"
              label="Korekta pozycji w pionie (liczba dodatnia - w dół, ujemna - w górę)"
              type="number"
            />
            <v-text-field
              v-model="catData.kerning"
              class="mb-n3"
              label="Kerning (liczba dodatnia - zwiększenie odstępu od następującego kota, ujemna - zmniejszenie)"
              type="number"
            />
          </v-card-text>
        </material-card>
      </v-col>
      <v-col
        cols="12"
        md="6"
      >
        <material-card
          heading="Nowy wariant"
          color="accent"
        >
          <v-card-text>
            <p>
              Wariant zostanie dodany jedynie, gdy zostanie wybrany plik do załadowania.
            </p>
            <v-text-field
              v-model="newVariant.height"
              class="mb-n3"
              label="Wysokość kota podana w centymetrach"
              type="number"
            />
            <v-text-field
              v-model="newVariant.fixX"
              class="mb-n3"
              label="Korekta pozycji w poziomie (liczba dodatnia - w prawy, ujemna w lewo)"
              type="number"
            />
            <v-text-field
              v-model="newVariant.fixY"
              class="mb-n3"
              label="Korekta pozycji w pionie (liczba dodatnia - w dół, ujemna - w górę)"
              type="number"
            />
            <v-text-field
              v-model="newVariant.kerning"
              class="mb-n3"
              label="Kerning (liczba dodatnia - zwiększenie odstępu od następującego kota, ujemna - zmniejszenie)"
              type="number"
            />
            <v-file-input
              id="file"
              ref="file"
              :clearable="true"
              accept="image/png"
              label="Zdjęcie kota"
              prepend-icon="mdi-camera"
              @change="handleUpload"
            />
          </v-card-text>
        </material-card>
      </v-col>
    </v-row>
    <v-row
      v-for="(variant, key) in catData.variants"
      :key="key"
    >
      <v-col>
        <material-card
          :heading="`Wariant ${key+1} ${variant.deleted ? '- usunięty' : ''}`"
          :color="variant.deleted ? 'grey' : 'accent'"
        >
          <v-row>
            <v-col
              cols="2"
            >
              <v-img
                :src="variant.lowResFilename"
                class="mt-2 mb-2"
                :class="variant.deleted ? 'grayscale' : ''"
                max-height="90"
                contain
                aspect-ratio="1"
              />
            </v-col>
            <v-col
              cols="8"
            >
              <v-text-field
                v-model="variant.height"
                class="mb-n3"
                label="Wysokość kota podana w centymetrach"
                type="number"
                :readonly="variant.deleted"
              />
              <v-text-field
                v-model="variant.fixX"
                class="mb-n3"
                label="Korekta pozycji w poziomie (liczba dodatnia - w prawy, ujemna w lewo)"
                type="number"
                :readonly="variant.deleted"
              />
              <v-text-field
                v-model="variant.fixY"
                class="mb-n3"
                label="Korekta pozycji w pionie (liczba dodatnia - w dół, ujemna - w górę)"
                type="number"
                :readonly="variant.deleted"
              />
              <v-text-field
                v-model="variant.kerning"
                class="mb-n3"
                label="Kerning (liczba dodatnia - zwiększenie odstępu od następującego kota, ujemna - zmniejszenie)"
                type="number"
                :readonly="variant.deleted"
              />
              <v-file-input
                ref="file"
                accept="image/png"
                label="Nowe zdjęcie kota"
                prepend-icon="mdi-camera"
                @change="((file) => handleVariantUpload(file, catData.variants[key]))"
              />
            </v-col>
            <v-col cols="2">
              <app-btn
                v-if="!variant.deleted"
                color="default"
                class="mr-2 mb-2"
                @click="onDeleteRecord(key)"
              >
                <v-icon
                  color="darken-2"
                >
                  mdi-trash-can-outline
                </v-icon>
              </app-btn>
              <app-btn
                v-if="variant.deleted"
                color="default"
                class="mr-2 mb-2"
                @click="onRestoreRecord(key)"
              >
                <v-icon
                  color="darken-2"
                >
                  mdi-restore
                </v-icon>
              </app-btn>
            </v-col>
          </v-row>
        </material-card>
      </v-col>
    </v-row>

    <ProgressPopup
      v-if="saveLoader"
      text="Zapisuję..."
    />
  </v-container>
</template>

<script>
import {Vue, Component, Prop} from 'vue-property-decorator';
import {api} from "@/util/helpers";
import ProgressPopup from "@/app/common/ProgressPopup";
import MaterialCard from "@/components/MaterialCard";

@Component({
  components: {MaterialCard, ProgressPopup}
})

export default class CatEdit extends Vue {
  @Prop(String) id

  zoom = 0
  newVariantFile = null
  newImage = null
  newVariant = {}
  saveLoader = false
  catData = {
    breedName: {},
    variants: [],
  }

  async mounted() {
    await this.fetchCat(this.id)
  }

  get variants() {
    return this.catData.variants
  }

  async fetchCat(catId) {
    if (catId !== "0") {
      const result = await api.get('/admin/cat/' + catId)
      if (result && result.data.success) {
        this.catData = result.data
      }
    }
  }

  onDeleteRecord(key) {
    const variant = this.catData.variants[key]
    variant.deleted = true
    this.catData.variants.splice(key, 1, variant)
  }

  onRestoreRecord(key) {
    const variant = this.catData.variants[key]
    variant.deleted = false
    this.catData.variants.splice(key, 1, variant)
  }

  handleUpload(file) {
    this.newImage = file
  }

  handleVariantUpload(file, variant) {
    variant.image = file
  }

  zoomIn() {
    this.zoom = (this.zoom + 10) || 100
  }

  async saveAndClose() {
    if (await this.save()) {
      await this.$router.push('/cats')
    }
  }

  async save() {
    this.saveLoader = true

    let formData = new FormData();
    formData.append("breedName.pl", this.catData.breedName.pl)
    formData.append("breedName.en", this.catData.breedName.en)
    formData.append("fixX", `${this.catData.fixX || 0}`)
    formData.append("fixY", `${this.catData.fixY || 0}`)
    formData.append("kerning", `${this.catData.kerning || 0}`)
    formData.append("height", `${this.catData.height || 0}`)

    formData.append("newVariant.fixX", `${this.newVariant.fixX || 0}`)
    formData.append("newVariant.fixY", `${this.newVariant.fixY || 0}`)
    formData.append("newVariant.kerning", `${this.newVariant.kerning || 0}`)
    formData.append("newVariant.height", `${this.newVariant.height || 0}`)
    formData.append("newImage", this.newImage)

    this.catData.variants.forEach((variant, key) => {
      formData.append(`variant[${key}].fixX`, `${variant.fixX || 0}`)
      formData.append(`variant[${key}].fixY`, `${variant.fixY || 0}`)
      formData.append(`variant[${key}].kerning`, `${variant.kerning || 0}`)
      formData.append(`variant[${key}].height`, `${variant.height || 0}`)
      formData.append(`variant[${key}].image`, variant.image)
      formData.append(`variant[${key}].deleted`, `${variant.deleted ? 1 : 0}`)
    })

    let result
    if (this.id !== "0") {
      result = await api.patch('/admin/cat/' + this.catData.id, formData, {
        'Content-Type': 'multipart/form-data'
      })
    } else {
      result = await api.post('/admin/cat', formData, {
        'Content-Type': 'multipart/form-data'
      })
    }

    if (!result || !result.data.success) {
      alert('Błąd podczas zapisu zmian w rasie kota')
      console.log(result)
      this.saveLoader = false
      return false
    }

    this.newImage = null
    this.saveLoader = false

    if (this.id === '0') {
      await this.$router.push('/cat/' + result.data.id)
    } else {
      this.catData = result.data
    }

    return true
  }

  zoomOut() {
    this.zoom = (this.zoom - 10) || 0
  }
}
</script>

<style lang="sass" scoped>
  .grayscale
    filter: grayscale(100%)
</style>

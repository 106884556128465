<template>
  <v-container
    id="regular-forms-view"
    fluid
    tag="section"
  >
    <v-row>
      <v-col
        cols="12"
        md="6"
      >
        <app-btn
          color="default"
          class="mr-2 mb-2"
          to="/quotes"
        >
          <v-icon>
            mdi-keyboard-return
          </v-icon>
        </app-btn>
        <app-btn
          color="default"
          class="mr-2 mb-2"
          @click="save"
        >
          {{ id === '0' ? 'Dodaj cytat' : 'Zapisz zmiany' }}
        </app-btn>
        <app-btn
          color="default"
          class="mr-2 mb-2"
          @click="saveAndClose"
        >
          {{ id === '0' ? 'Dodaj cytat i zamknij' : 'Zapisz zmiany i zamknij' }}
        </app-btn>
      </v-col>
    </v-row>

    <v-row>
      <v-col
        cols="12"
        md="6"
      >
        <material-card
          heading="Podstawowe dane"
          color="accent"
        >
          <v-card-text>
            <v-text-field
              v-model="quoteData.title"
              class="mb-n3"
              label="Nazwa"
            />
            <v-text-field
              v-model="quoteData.tags"
              class="mb-n3"
              label="Tagi (oddziel przecinkami)"
            />
            <v-file-input
              id="file"
              ref="file"
              accept="image/png"
              label="Dodaj/zmień zdjęcie"
              prepend-icon="mdi-camera"
              @change="handleUpload"
            />
          </v-card-text>
        </material-card>
      </v-col>
      <v-col
        v-if="id !== 0"
        cols="12"
        md="6"
      >
        <v-img
          :src="quoteData.filename"
        />
      </v-col>
    </v-row>

    <ProgressPopup
      v-if="saveLoader"
      text="Zapisuję..."
    />
  </v-container>
</template>

<script>
import {Vue, Component, Prop} from 'vue-property-decorator';
import { set, get } from 'vuex-module-decorators'
import axios from 'axios'
import {api} from "@/util/helpers";
import ProgressPopup from "@/app/common/ProgressPopup";
import MaterialCard from "@/components/MaterialCard";

@Component({
  components: {ProgressPopup, MaterialCard}
})
export default class QuoteEdit extends Vue {
  @Prop(String) id

  zoom = 0
  saveLoader = false
  newImage = null
  quoteData = {
    title: ''
  }

  async mounted() {
    await this.fetchQuote(this.id)
  }

  async fetchQuote(quoteId) {
    if (quoteId !== "0") {
      const result = await api.get('/admin/quote/' + quoteId)
      if (result && result.data.success) {
        this.quoteData = result.data
      }
    }
  }

  handleUpload(file) {
    this.newImage = file
  }

  zoomIn() {
    this.zoom = (this.zoom + 10) || 100
  }

  async saveAndClose() {
    if (await this.save()) {
      await this.$router.push('/quotes')
    }
  }

  async save() {
    this.saveLoader = true

    let formData = new FormData();
    formData.append("title", this.quoteData.title || '')
    formData.append("tags", this.quoteData.tags || '')
    formData.append("newImage", this.newImage)

    let result
    if (this.id !== "0") {
      result = await api.patch('/admin/quote/' + this.quoteData.id, formData, {
        'Content-Type': 'multipart/form-data'
      })
    } else {
      result = await api.post('/admin/quote', formData, {
        'Content-Type': 'multipart/form-data'
      })

    }
    if (!result || !result.data.success) {
      alert('Błąd podczas zapisu zmian w cytacie')
      console.log(result)
      this.saveLoader = false
      return false
    }

    this.saveLoader = false
    if (this.id === '0') {
      await this.$router.push('/quote/' + result.data.id)
    } else {
      this.quoteData = result.data
    }
    return true
  }

  zoomOut() {
    this.zoom = (this.zoom - 10) || 0
  }
}
</script>

<template>
  <v-container
    id="data-tables-view"
    fluid
    tag="section"
  >
    <v-row class="mb-10">
      <v-col
        cols="12"
        md="6"
      >
        <app-btn
          color="default"
          class="mr-2 mb-2"
          @click="loadData"
        >
          <v-icon
            color="darken-2"
          >
            mdi-refresh
          </v-icon>
        </app-btn>
        <v-btn
          color="default"
          class="mb-2"
          @click="onAddNewItem"
        >
          Dodaj nowe tło
        </v-btn>
      </v-col>
    </v-row>

    <material-card
      icon="mdi-vuetify"
      icon-small
      color="accent"
      title="Tła"
    >
      <v-card-text>
        <v-text-field
          v-model="search"
          append-icon="mdi-magnify"
          class="ml-auto"
          hide-details
          label="Wyszukaj"
          single-line
          style="max-width: 250px;"
          @change="onSearch"
        />

        <v-divider class="mt-3" />

        <v-data-table
          :items-per-page="options.itemsPerPage"
          :headers="headers"
          :items="items"
          :search.sync="search"
          :sort-by="['title']"
          :sort-desc="[false]"
          multi-sort
          :loading="loading"
          loading-text="Ładuję dane, proszę czekać..."
          :options.sync="options"
          :server-items-length="totalRows"
          :footer-props="{
            showFirstLastPage: true,
            firstIcon: 'mdi-arrow-collapse-left',
            lastIcon: 'mdi-arrow-collapse-right',
            prevIcon: 'mdi-minus',
            nextIcon: 'mdi-plus'
          }"
          @update:options="onUpdate"
          @update:page="onUpdatePage"
          @update:items-per-page="onUpdateItemsPerPage"
        >
          <template #[`item.lowResFilename`]="{ item }">
            <v-img
              :src="item.lowResFilename"
              class="mt-2 mb-2"
              max-height="90"
              max-width="180"
              contain
            />
          </template>
          <template #[`item.actions`]="{ item }">
            <v-icon
              class="mr-4"
              @click="onEditItem(item)"
            >
              mdi-pencil
            </v-icon>
            <v-icon
              @click="onDeleteItem(item)"
            >
              mdi-delete
            </v-icon>
          </template>
        </v-data-table>
      </v-card-text>
    </material-card>

    <v-dialog
      v-model="dialog"
      width="500"
    >
      <v-card>
        <v-card-title class="text-h5 grey lighten-2">
          Na pewno usunąć te tło?
        </v-card-title>

        <v-card-text>
          <br>
          <v-img
            v-if="itemToDelete !== null"
            :src="itemToDelete.lowResFilename"
            class="mt-2 mb-2"
            max-height="180"
            contain
          />
        </v-card-text>

        <v-divider />

        <v-card-actions>
          <v-spacer />
          <v-btn
            color="primary"
            text
            @click="onDelete"
          >
            Tak
          </v-btn>
          <v-btn
            color="primary"
            text
            @click="dialog = false"
          >
            Nie
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-container>
</template>

<script>
import { Component } from 'vue-property-decorator';
import {api} from "@/util/helpers";
import ListStore from "@/app/mixins/listStore";

@Component
export default class Backgrounds extends ListStore {
  viewName = 'backgrounds'

  headers = [
    {
      text: 'Zdjęcie',
      value: 'lowResFilename',
      sortable: false,
    },
    {
      text: 'Nazwa',
      value: 'title',
    },
    {
      text: 'Rodzaj',
      value: 'width',
    },
    {
      text: 'Tagi',
      value: 'tags',
    },
    {
      sortable: false,
      text: 'Działania',
      value: 'actions',
    },
  ];

  items = []
  loading = true
  totalRows = 0
  dialog = false
  itemToDelete = null

  onEditItem(item) {
    this.$router.push('/background/' + item.id)
  }

  onAddNewItem() {
    this.$router.push('/background/0')
  }

  onDeleteItem(item) {
    this.itemToDelete = item
    this.dialog = true
  }

  async onDelete() {
    if (this.itemToDelete) {
      this.loading = true
      const url = '/admin/background/' + this.itemToDelete.id
      const result = await api.delete(url)
      if (!result || !result.data.success) {
        alert('Błąd podczas próby usunięcia tła.')
        this.loading = false
        return
      }
      this.dialog = false
      this.itemToDelete = null
      await this.loadData()
    }
  }

  async loadData() {
    this.loading = true
    const url = '/admin/backgrounds/' + this.buildListParam()
    const result = await api.get(url)
    if (!result || !result.data.success) {
      alert('Błąd podczas próby pobrania listy teł.')
      return
    }

    if (result.data.backgrounds !== null) {
      this.items = result.data.backgrounds.map(background => {
        let width = ''
        width += background.fullImageUrl ? 'Pełna szerokość' : ''
        if (width && background.partialImageUrl) {
          width += ' i połowa'
        }
        if (!width && background.partialImageUrl) {
          width += 'Połowa'
        }
        return {
          id: background.id,
          title: background.title || '[brak]',
          lowResFilename: background.lowResFilename,
          tags: background.tags?.join(', ') || '',
          width,
        }
      })
    }

    this.totalRows = result.data.pagination.totalRows
    this.loading = false
  }
}
</script>

<style scoped>

</style>

// Imports
import Vue from 'vue'
import Router from 'vue-router'
import {
  layout,
  route,
} from '@/util/routes'

import Order from '@/app/modules/orders/Orders'
import OrderEdit from "@/app/modules/orders/OrderEdit";
import Products from "@/app/modules/products/Products";
import ProductEdit from "@/app/modules/products/ProductEdit";
import Categories from "@/app/modules/categories/Categories";
import CategoryEdit from "@/app/modules/categories/CategoryEdit";
import Cups from "@/app/modules/cups/Cups";
import CupEdit from "@/app/modules/cups/CupEdit";
import Dogs from "@/app/modules/dogs/Dogs";
import DogEdit from "@/app/modules/dogs/DogEdit";
import Cats from "@/app/modules/cats/Cats";
import CatEdit from "@/app/modules/cats/CatEdit";
import Rabbits from "@/app/modules/rabbits/Rabbbits";
import RabbitEdit from "@/app/modules/rabbits/RabbitEdit";
import MaleBodies from "@/app/modules/maleBody/MaleBodies";
import MaleBodyEdit from "@/app/modules/maleBody/MaleBodyEdit";
import FemaleBodies from "@/app/modules/femaleBody/FemaleBodies";
import FemaleBodyEdit from "@/app/modules/femaleBody/FemaleBodyEdit";
import MaleHairstyles from "@/app/modules/maleHairstyle/MaleHairstyles";
import MaleHairstyleEdit from "@/app/modules/maleHairstyle/MaleHairstyleEdit";
import FemaleHairstyles from "@/app/modules/femaleHairstyle/FemaleHairstyles";
import FemaleHairstyleEdit from "@/app/modules/femaleHairstyle/FemaleHairstyleEdit";

import BoyBodies from "@/app/modules/boyBody/BoyBodies";
import BoyBodyEdit from "@/app/modules/boyBody/BoyBodyEdit";
import BoyHairstyles from "@/app/modules/boyHairstyle/BoyHairstyles";
import BoyHairstyleEdit from "@/app/modules/boyHairstyle/BoyHairstyleEdit";
import GirlBodies from "@/app/modules/girlBody/GirlBodies";
import GirlBodyEdit from "@/app/modules/girlBody/GirlBodyEdit";
import GirlHairstyles from "@/app/modules/girlHairstyle/GirlHairstyles";
import GirlHairstyleEdit from "@/app/modules/girlHairstyle/GirlHairstyleEdit";

import Quotes from "@/app/modules/quotes/Quotes";
import QuoteEdit from "@/app/modules/quotes/QuoteEdit";
import Backgrounds from "@/app/modules/backgrounds/Backgrounds";
import BackgroundEdit from "@/app/modules/backgrounds/BackgroundEdit";
import ClearCache from "@/app/modules/clearCache/ClearCache";
import Discounts from "@/app/modules/discounts/Discounts";
import DiscountEdit from "@/app/modules/discounts/DiscountEdit";

import MaterialAlert from "@/components/MaterialAlert";

Vue.use(Router)

const router = new Router({
  mode: 'history',
  base: process.env.BASE_URL,
  scrollBehavior: (to, from, savedPosition) => {
    if (to.hash) {
      return { selector: to.hash }
    }
    if (savedPosition) {
      return savedPosition
    }

    return { x: 0, y: 0 }
  },
  routes: [
    layout('Default', [
      // Orders
      route('Zamówienia', Order, 'orders'),
      route('Edycja zamówienia', OrderEdit, 'order/:id'),

      // Dashboard
      route('Dashboard', Order, ''),

      // Products
      route('Produkty', Products, 'products'),
      route('Edycja produktu', ProductEdit, 'product/:id'),

      // Categories
      route('Kategorie', Categories, 'categories'),
      route('Edycja kategorii', CategoryEdit, 'category/:id'),

      // Kubki
      route('Kubki', Cups, 'cups'),
      route('Edycja kubka', CupEdit, 'cup/:id'),

      // Rasy psów
      route('Rasy psów', Dogs, 'dogs'),
      route('Edycja rasy psa', DogEdit, 'dog/:id'),

      // Rasy kotów
      route('Koty', Cats, 'cats'),
      route('Edycja kota', CatEdit, 'cat/:id'),

      // Rasy królików
      route('Króliki', Rabbits, 'rabbits'),
      route('Edycja królika', RabbitEdit, 'rabbit/:id'),

      // Ciała męskie
      route('Body męskie', MaleBodies, 'male-bodies'),
      route('Edycja ciała męskiego', MaleBodyEdit, 'male-body/:id'),

      // Ciała żeńskie
      route('Body żeńskie', FemaleBodies, 'female-bodies'),
      route('Edycja ciała kobiecego', FemaleBodyEdit, 'female-body/:id'),

      // Włosy męskie
      route('Włosy męskie', MaleHairstyles, 'male-hairstyles'),
      route('Edycja włosów męskich', MaleHairstyleEdit, 'male-hairstyle/:id'),

      // Włosy żeńskie
      route('Włosy żeńskie', FemaleHairstyles, 'female-hairstyles'),
      route('Edycja włosów żeńskich', FemaleHairstyleEdit, 'female-hairstyle/:id'),

      // Body chłopców
      route('Body chłopców', BoyBodies, 'boy-bodies'),
      route('Edycja ciała chłopca', BoyBodyEdit, 'boy-body/:id'),

      // Body dziewczyn
      route('Body dziewczyn', GirlBodies, 'girl-bodies'),
      route('Edycja ciała dziewczyny', GirlBodyEdit, 'girl-body/:id'),

      // Włosy chłopców
      route('Włosy chłopców', BoyHairstyles, 'boy-hairstyles'),
      route('Edycja włosów chłopięcych', BoyHairstyleEdit, 'boy-hairstyle/:id'),

      // Włosy dziewczyn
      route('Włosy dziewczyn', GirlHairstyles, 'girl-hairstyles'),
      route('Edycja włosów dziewczęcych', GirlHairstyleEdit, 'girl-hairstyle/:id'),

      // Quotes
      route('Cytaty', Quotes, 'quotes'),
      route('Edycja cytatu', QuoteEdit, 'quote/:id'),

      // Backgrounds
      route('Tła', Backgrounds, 'backgrounds'),
      route('Edycja tła', BackgroundEdit, 'background/:id'),

      // Discounts
      route('Zniżki', Discounts, 'discounts'),
      route('Edycja Zniżki', DiscountEdit, 'discount/:id'),

      // Cache
      route('Zarządzanie buforem', ClearCache, 'clear-cache'),

      // Rabaty
      route('Rabaty', Discounts, 'discounts'),

      // Pages
      route('Timeline', null, 'components/timeline'),
      route('UserProfile', null, 'components/profile'),

      // Components
      route('Buttons', null, 'components/buttons'),
      route('Grid', null, 'components/grid'),
      route('Tabs', null, 'components/tabs'),
      route('Notifications', null, 'components/notifications'),
      route('Icons', null, 'components/icons'),
      route('Typography', null, 'components/typography'),

      // Forms
      route('Regular Forms', null, 'forms/regular'),
      route('Extended Forms', null, 'forms/extended'),
      route('Validation Forms', null, 'forms/validation'),
      route('Wizard', null, 'forms/wizard'),

      // Tables
      route('Regular Tables', null, 'tables/regular'),
      route('Extended Tables', null, 'tables/extended'),
      route('Data Tables', null, 'tables/data-tables'),

      // Maps
      route('Google Maps', null, 'maps/google'),
      route('Fullscreen Map', null, 'maps/fullscreen'),

      route('Rtl', null, 'pages/rtl'),
      route('Widgets', null, 'widgets'),
      route('Charts', null, 'charts'),
      route('Calendar', null, 'calendar'),
    ]),
    layout('Page', [
      route('Error', null, 'error'),
      route('Lock', null, 'lock'),
      route('Login', null, 'login'),
      route('Pricing', null, 'pricing'),
      route('Register', null, 'register'),
    ], '/pages'),
  ],
})

router.beforeEach((to, from, next) => {
  if (to.path !== '/pages/login') {
    if (localStorage.getItem('token') === null) {
      next({
        path: '/pages/login',
        params: { nextUrl: to.fullPath },
      })
    }
  }

  return next()
})

export default router

<template>
  <v-container
    id="regular-forms-view"
    fluid
    tag="section"
  >
    <v-row>
      <v-col
        cols="12"
        md="6"
      >
        <app-btn
          color="default"
          class="mr-2 mb-2"
          to="/girl-bodies"
        >
          <v-icon>
            mdi-keyboard-return
          </v-icon>
        </app-btn>
        <app-btn
          color="default"
          class="mr-2 mb-2"
          @click="save"
        >
          {{ id === '0' ? 'Dodaj body' : 'Zapisz zmiany' }}
        </app-btn>
        <app-btn
          color="default"
          class="mr-2 mb-2"
          @click="saveAndClose"
        >
          {{ id === '0' ? 'Dodaj body i zamknij' : 'Zapisz zmiany i zamknij' }}
        </app-btn>
      </v-col>
    </v-row>

    <v-row>
      <v-col
        cols="12"
        md="6"
      >
        <material-card
          heading="Podstawowe dane"
          color="accent"
        >
          <v-card-text>
            <v-text-field
              v-model="bodyData.name"
              class="mb-n3"
              label="Nazwa"
            />
            <v-text-field
              v-model="bodyData.tags"
              class="mb-n3"
              label="Tagi (oddziel przecinkami)"
            />
            <v-text-field
              v-model="bodyData.fixX"
              class="mb-n3"
              label="Korekta pozycji body w poziomie (liczba dodatnia - w prawy, ujemna - w lewo)"
              type="number"
            />
            <v-text-field
              v-model="bodyData.fixY"
              class="mb-n3"
              label="Korekta pozycji body w pionie (liczba dodatnia - w dół, ujemna - w górę)"
              type="number"
            />
            <v-text-field
              v-model="bodyData.scale"
              class="mb-n3"
              label="Zmniejszenie body (w procentach)"
              type="number"
            />
            <v-file-input
              id="file"
              ref="file"
              accept="image/png"
              label="Dodaj/zamień zdjęcie"
              prepend-icon="mdi-camera"
              @change="handleFileUpload"
            />
          </v-card-text>
        </material-card>
      </v-col>
      <v-col
        v-if="id !== '0'"
        :key="reloadKey"
        cols="12"
        md="6"
        style="position: relative"
      >
        <img
          :style="`position: absolute; top: 0; left: 0; zoom: ${bodyData.scale/100}`"
          :src="bodyData.sourceUrl"
        >
        <img
          :style="`position: absolute; top: 0; left: 0; zoom: ${bodyData.scale/100}`"
          :src="bodyData.sampleHairstyleUrl"
        >
      </v-col>
    </v-row>

    <ProgressPopup
      v-if="saveLoader"
      text="Zapisuję..."
    />
  </v-container>
</template>

<script>
  import { Vue, Component, Prop } from 'vue-property-decorator';
  import { api } from "@/util/helpers";
  import ProgressPopup from "@/app/common/ProgressPopup";

  @Component({
    components: {ProgressPopup}
  })
  export default class GirlBodyEdit extends Vue {
    @Prop(String) id

    zoom = 0
    saveLoader = false
    file = null
    reloadKey = 0
    bodyData = {
      name: '',
      fixX: 0,
      fixY: 0,
      sourceUrl : '',
      scale: 0,
    }

    async mounted() {
      await this.fetchDog(this.id)
    }

    async fetchDog(maleBodyId) {
      if (maleBodyId !== "0") {
        const result = await api.get('/admin/girl-body/' + maleBodyId)
        if (result && result.data.success) {
          this.bodyData = result.data
        }
      }
    }

    zoomIn() {
      this.zoom = (this.zoom + 10) || 100
    }

    handleFileUpload(file) {
      this.file = file;
    }

    async saveAndClose() {
      if (await this.save()) {
        await this.$router.push('/girl-bodies')
      }
    }

    async save() {
      this.saveLoader = true

      let formData = new FormData();
      formData.append("name", this.bodyData.name);
      formData.append("tags", this.bodyData.tags || '')
      formData.append("fixX", +this.bodyData.fixX);
      formData.append("fixY", +this.bodyData.fixY);
      formData.append("scale", +this.bodyData.scale);
      formData.append("newImage", this.file);

      let result
      if (this.id !== "0") {
        result = await api.patch('/admin/girl-body/' + this.bodyData.id, formData, {
          'Content-Type': 'multipart/form-data'
        })
      } else {
        result = await api.post('/admin/girl-body', formData, {
          'Content-Type': 'multipart/form-data'
        })
      }
      if (!result || !result.data.success) {
        alert('Błąd podczas zapisu zmian')
        console.log(result)
        this.saveLoader = false
        return false
      }

      this.saveLoader = false
      if (this.id === "0") {
        await this.$router.push('/girl-body/' + result.data.id)
      } else {
        this.bodyData.sourceUrl = result.data.sourceUrl
        this.reloadImage()
      }
      return true
    }

    reloadImage() {
      this.reloadKey++
    }

    zoomOut() {
      this.zoom = (this.zoom - 10) || 0
    }
  }
</script>
